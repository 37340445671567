import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, useTheme, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomButton from "../Elements/CustomButton";
import { updateTransactionHistory } from "../../services/retool-service";
import toast from "react-hot-toast";
import moment from "moment";
import { BusinessData } from "../../../data/businessData";
import CustomSelect from "../Elements/CustomSelect";

const UpdateTransactionHistoryModal = ({
	open,
	onClose,
	selectedRow,
	initialData,
	handleRefetch,
}) => {
	const theme = useTheme();
	const primaryCategories = BusinessData()?.primaryCategories || [];
	const secondaryCategories = BusinessData()?.secondaryCategories || [];

	const [formData, setFormData] = useState({
		date: "",
		amount: "",
		balance: "",
		memo: "",
		primaryCategory: "",
		secondaryCategory: "",
	});
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (initialData) {
			setFormData({
				date: initialData.date
					? moment(initialData.date).format("YYYY-MM-DD")
					: "",
				amount: initialData.amount || "",
				balance: initialData.balance || "",
				memo: initialData.memo || "",
				primaryCategory: initialData.primaryCategory || "",
				secondaryCategory: initialData.secondaryCategory || "",
			});
		}
	}, [initialData]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		const data = { ...selectedRow };
		data.primaryCategory = formData.primaryCategory;
		data.secondaryCategory = formData.secondaryCategory;
		data.amount = formData.amount;
		data.date = formData.date
			? moment(formData.date).format("YYYYMMDD000000")
			: "";
		data.balance = formData.balance;
		data.memo = formData.memo;
		const payload = {
			documentId: selectedRow?.documentId,
			itemId: selectedRow?.id,
			item: data,
		};

		try {
			const response = await updateTransactionHistory(payload);
			if (response.data.success) {
				toast.success("Transaction updated successfully");
				handleRefetch();
				setFormData({
					date: "",
					amount: "",
					balance: "",
					memo: "",
					primaryCategory: "",
					secondaryCategory: "",
				});
				onClose();
			} else {
				toast.error(response.data.message || "Failed to update transaction");
			}
		} catch (error) {
			toast.error("Error updating transaction");
		}
		setIsLoading(false);
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "500px",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "8px",
					margin: "10% auto",
					position: "relative",
					color: theme.palette.text.primary,
					maxHeight: "80vh", // Limits the height of the modal
					overflowY: "auto", // Enables scrolling
				}}
			>
				{/* Close Icon */}
				<IconButton
					onClick={onClose}
					sx={{
						position: "absolute",
						top: "10px",
						right: "10px",
						color: theme.palette.text.primary,
					}}
				>
					<CloseIcon />
				</IconButton>

				<Typography mb={3} sx={{ fontSize: "20px", fontWeight: "500" }}>
					Update Transaction History
				</Typography>

				<CustomTextInput
					label="Date"
					name="date"
					value={formData.date}
					onChange={handleChange}
					type="date"
				/>
				<CustomTextInput
					label="Amount"
					name="amount"
					value={formData.amount}
					onChange={handleChange}
					type="text"
				/>
				<CustomTextInput
					label="Balance"
					name="balance"
					value={formData.balance}
					onChange={handleChange}
					type="text"
				/>
				<CustomTextInput
					label="Memo"
					name="memo"
					value={formData.memo}
					onChange={handleChange}
					multiline
					rows={2}
				/>

				<CustomSelect
					label="Primary Category"
					name="primaryCategory"
					value={formData.primaryCategory}
					onChange={handleChange}
					options={primaryCategories.map((category) => ({
						label: category,
						value: category,
					}))}
					otherLabel={false}
				/>

				<CustomSelect
					label="Secondary Category"
					name="secondaryCategory"
					value={formData.secondaryCategory}
					onChange={handleChange}
					options={secondaryCategories.map((category) => ({
						label: category,
						value: category,
					}))}
					otherLabel={false}
				/>

				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						gap: "10px",
						marginTop: "20px",
					}}
				>
					<CustomButton
						text="Cancel"
						variant="outlined"
						color="secondary"
						onClick={onClose}
					/>
					<CustomButton
						text="Update"
						variant="contained"
						onClick={handleSubmit}
						disabled={isLoading}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default UpdateTransactionHistoryModal;
