import React, { useState } from "react";
import { Modal, Box, Typography, useTheme, IconButton } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomButton from "../Elements/CustomButton";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";
import { uploadLabelledTransactions } from "../../services/retool-service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CustomSelect from "../Elements/CustomSelect";
import { useFetchClientBankAccounts } from "../../../common/helpers/hooks/useBankAccountService";

interface CsvTransactionsUploadModalProps {
	open: boolean;
	onClose: () => void;
	userId: string;
}

const LabelledTransactionsUploadModal: React.FC<
	CsvTransactionsUploadModalProps
> = ({ open, onClose, userId }) => {
	const theme = useTheme();
	const [bankAccountId, setBankAccountId] = useState(0);
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const { data: bankAccounts = [], isLoading: isLoadingBankAccounts } =
		useFetchClientBankAccounts(userId);
	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"text/csv": [".csv"],
		},
		multiple: true,
		onDrop: (acceptedFiles) => {
			setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
		},
	});

	const handleFileRemove = (fileToRemove: File) => {
		setSelectedFiles((prevFiles) =>
			prevFiles.filter((file) => file !== fileToRemove)
		);
	};

	const handleUploadSubmit = async () => {
		if (!bankAccountId) {
			toast.error("Please select a bank account.");
			return;
		}

		setIsLoading(true);

		try {
			const formData = new FormData();
			formData.append("userId", userId);
			formData.append("bankAccountId", bankAccountId);
			selectedFiles.forEach((file) => {
				formData.append("File", file);
			});

			const response = await uploadLabelledTransactions(formData);

			if (response?.data?.success) {
				toast.success(response.data.message);
				handleUploadDialogClose();
			} else {
				toast.error(response?.data?.message || "Failed to upload documents.");
			}
		} catch (error) {
			toast.error("Failed to upload document.");
		} finally {
			setIsLoading(false);
		}
	};

	const handleUploadDialogClose = () => {
		setSelectedFiles([]);
		setBankAccountId(0);
		onClose();
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "600px",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "8px",
					margin: "auto",
					top: "10%",
					position: "relative",
					color: theme.palette.text.primary,
					overflowY: "auto",
				}}
			>
				<Typography
					mb={3}
					sx={{
						fontSize: "24px",
						fontWeight: "500",
					}}
				>
					Upload CSV Transactions
				</Typography>

				<Box
					{...getRootProps()}
					sx={{
						border: "2px dashed #cccccc",
						padding: "20px",
						textAlign: "center",
						marginBottom: "10px",
					}}
				>
					<input {...getInputProps()} />
					<Typography variant="body1">
						Drag 'n' drop some files here, or click to select files
					</Typography>
				</Box>

				{selectedFiles.length > 0 && (
					<Box sx={{ marginTop: "10px" }}>
						<strong>Selected Files:</strong>
						<ul>
							{selectedFiles.map((file, index) => (
								<li
									key={index}
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									{file.name}
									<IconButton
										onClick={() => handleFileRemove(file)}
										size="small"
										sx={{ color: theme.palette.error.main }}
									>
										<DeleteIcon />
									</IconButton>
								</li>
							))}
						</ul>
					</Box>
				)}

				<CustomSelect
					name="bankName"
					label="Select Bank"
					value={bankAccountId || ""}
					onChange={(e: any) => setBankAccountId(Number(e.target.value))}
					options={
						bankAccounts?.data?.data?.map((row: any) => ({
							label: `${row.bankName} ${row.accountNumber}`,
							value: row.id,
						})) || []
					}
					sx={{ mb: 2 }}
					error={
						isLoadingBankAccounts && bankAccounts?.data?.data?.length === 0
					}
					helperText={
						isLoadingBankAccounts
							? "Loading bank accounts..."
							: bankAccounts?.data?.data?.length === 0
								? "No bank accounts available"
								: ""
					}
					otherLabel={false}
				/>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						gap: "10px",
						marginTop: "20px",
					}}
				>
					<CustomButton
						text="Cancel"
						variant="outlined"
						color="secondary"
						onClick={handleUploadDialogClose}
					/>

					<CustomButton
						text="Submit"
						variant="contained"
						onClick={handleUploadSubmit}
						disabled={isLoading || selectedFiles.length === 0 || !bankAccountId}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default LabelledTransactionsUploadModal;
