import React from "react";
import {
	Box,
	Typography,
	IconButton,
	Modal,
	Grid,
	useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomTable from "../Tables/CustomTable";

interface DefaultsModalProps {
	open: boolean;
	onClose: () => void;
	data: any;
	type: "consumer" | "commercial";
}

const DefaultsModal: React.FC<DefaultsModalProps> = ({
	open,
	onClose,
	data,
	type,
}) => {
	const theme = useTheme();

	const consumerColumns = [
		{ title: "Consumer No", key: "consumerNo" },
		{ title: "Contact Name", key: "contactName" },
		{ title: "Supplier Name", key: "supplierName" },
		{ title: "Default Amount", key: "defaultAmount" },
		{ title: "Default Type", key: "defaultType" },
		{ title: "Written Off Date", key: "writtenOffDate" },
	];

	const businessColumns = [
		{ title: "Product", key: "majorProduct" },
		{ title: "Number", key: "numberFound" },
		{ title: "Date", key: "defaultDate" },
		{ title: "Name", key: "defaultName" },
		{ title: "Trade Style", key: "defaultTradeStyle" },
		{ title: "Address", key: "defaultAddress" },
		{ title: "Suburb", key: "suburb" },
		{ title: "City", key: "city" },
		{ title: "Country", key: "country" },
		{ title: "Code", key: "postCode" },
		{ title: "Amount", key: "amount" },
		{ title: "Comment", key: "comment" },
		{ title: "Subscriber", key: "subscriberName" },
		{ title: "Supplier", key: "supplierName" },
		{ title: "Message", key: "message" },
		// { title: "SerialNo", key: "serialNo" },
		// { title: "On Behalf Of", key: "onBehalfOf" },
		// { title: "Status", key: "status" },
		// { title: "Paid-Indicator", key: "paidInFullIndicator" },
		// { title: "Paid-Date", key: "paidInFullDate" },
		// { title: "IT", key: "itNumber" },
	];

	const columns = type === "consumer" ? consumerColumns : businessColumns;
	const defaultData =
		type === "consumer"
			? data?.consumerDefaults || []
			: data?.businessDefaults || [];

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100vw",
					height: "100vh",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					position: "relative",
					top: "0%",
					overflowY: "auto",
				}}
			>
				<Box
					mb={2}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h6">
						{type === "consumer"
							? "Consumer Defaults Details"
							: "Business Defaults Details"}
					</Typography>
					<IconButton edge="end" color="inherit" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>

				<Box p={2}>
					<Grid container spacing={2}>
						<Box mt={4}>
							<Typography variant="h6">
								{type === "consumer"
									? "Personal Defaults"
									: "Business Defaults"}
							</Typography>
							<CustomTable
								columns={columns}
								data={defaultData}
								copyToClipboard={true}
							/>
						</Box>
					</Grid>
				</Box>
			</Box>
		</Modal>
	);
};

export default DefaultsModal;
