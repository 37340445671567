import React, { useState } from "react";
import {
	Box,
	Typography,
	IconButton,
	Modal,
	useTheme,
	Tabs,
	Tab,
	Card,
	InputLabel,
	CardContent,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Select,
	Grid,
	MenuItem,
	FormControl,
	TableCell,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";

interface CreditScoreCardModalProps {
	open: boolean;
	onClose: () => void;
}

const severityColors: Record<string, string> = {
	red: "#E57373",
	amber: "#FBC02D",
	green: "#A7E0A7",
};

const SectionTitle = styled(Typography)({
	backgroundColor: "#003f5c",
	color: "white",
	padding: "8px",
	textAlign: "center",
	fontWeight: "bold",
	borderRadius: "4px",
});

const SubSectionTitle = styled(Typography)({
	backgroundColor: "#e3ebf0",
	color: "black",
	padding: "6px",
	fontWeight: "bold",
	borderRadius: "4px",
	textAlign: "center",
});

const recommendationOptions: { value: string; color: string }[] = [
	{ value: "In Support", color: "#A7E0A7" }, // Green
	{ value: "Conditional", color: "#FBC02D" }, // Amber
	{ value: "Reject", color: "#E57373" }, // Red
];

type DecisionState = {
	salesDecision: string;
	creditDecision: string;
	creditCommitteeDecision: string;
	salesRationale: string;
	creditRationale: string;
	creditCommitteeRationale: string;
};

interface Option {
	value: string;
	severity: "green" | "amber" | "orange" | "red";
}

type AnswersType = Record<string, string>;

type QuestionSection = {
	label: string;
	field: string;
	options?: Option[];
};

type QuestionSections = Record<string, QuestionSection[]>;

const questionSections: QuestionSections = {
	honesty: [
		{
			label:
				"2.1.1 Is the applicant truthful about their estimated annual revenue?",
			field: "estimatedRevenue",
			options: [
				{ value: "Estimate > Actual Revenue + 100%", severity: "red" },
				{
					value: "Actual Revenue +10% < Estimate < Actual Revenue + 20%",
					severity: "amber",
				},
				{
					value: "Actual Revenue +20% < Estimate < Actual Revenue + 50%",
					severity: "orange",
				},
				{ value: "Estimate < Actual Revenue + 100%", severity: "green" },
			],
		},
		{
			label:
				"2.1.2 Does the applicant report accurately the number of permanent employees?",
			field: "numEmployees",
			options: [
				{ value: "Reports accurately", severity: "green" },
				{
					value: "Reports inaccurately - with up to +-2 FTE",
					severity: "amber",
				},
				{
					value: "Reports inaccurately - with up to +-5 FTE",
					severity: "orange",
				},
				{ value: "Reports inaccurately - with over +-6 FTE", severity: "red" },
			],
		},
		{
			label:
				"2.1.3 Does the applicant report accurately the personal loan(s) in arrears?",
			field: "personalLoans",
			options: [
				{
					value: "Reports accurately - positive or negative",
					severity: "green",
				},
				{
					value: "Fails to disclaim personal loan(s) in arrears <R10,000",
					severity: "amber",
				},
				{
					value: "Fails to disclaim personal loan(s) in arrears <R50,000",
					severity: "orange",
				},
				{
					value: "Fails to disclaim personal loan(s) in arrears >R50,000",
					severity: "red",
				},
			],
		},
	],
	responsibility: [
		{
			label:
				"2.2.1 What is the total # of gambling or betting transactions per month?",
			field: "gamblingTransactions",
		},
		{
			label:
				"2.2.2 What is the average # of alcohol purchase transactions per month?",
			field: "alcoholPurchases",
		},
		{
			label: "2.2.3 What is their business credit score?",
			field: "businessCreditScore",
		},
	],
	reputation: [
		{
			label: "2.3.1 Is there any evidence of adverse media report(s)?",
			field: "adverseMedia",
			options: [
				{ value: "YES", severity: "red" },
				{ value: "NO", severity: "green" },
			],
		},
		{
			label: "2.3.2 Is the applicant recommended by a client in good standing?",
			field: "clientRecommendation",
			options: [
				{ value: "YES", severity: "green" },
				{ value: "NO", severity: "amber" },
			],
		},
	],
};

const CreditScoreCardModal: React.FC<CreditScoreCardModalProps> = ({
	open,
	onClose,
}) => {
	const theme = useTheme();
	const [activeTab, setActiveTab] = useState(0);

	const [answers, setAnswers] = useState<AnswersType>({
		estimatedRevenue: "",
		numEmployees: "",
		personalLoans: "",
		gamblingTransactions: "0",
		alcoholPurchases: "0",
		businessCreditScore: "80",
		adverseMedia: "",
		clientRecommendation: "",
	});

	const getSeverityColor = (field: string, value: string): string => {
		if (field === "businessCreditScore") {
			const score = parseInt(value, 10);
			if (score > 79) return severityColors.green;
			if (score > 59) return severityColors.amber;
			if (score > 39) return severityColors.orange;
			return severityColors.red;
		}
		if (field === "gamblingTransactions" || field === "alcoholPurchases") {
			return parseInt(value, 10) > 1
				? severityColors.red
				: severityColors.green;
		}

		for (const section of Object.values(questionSections)) {
			for (const question of section) {
				if (question.field === field && question.options) {
					const option = question.options.find((opt) => opt.value === value);
					if (option) {
						return severityColors[option.severity];
					}
				}
			}
		}

		return severityColors.red;
	};

	const [decisions, setDecisions] = useState<DecisionState>({
		salesDecision: "In Support",
		creditDecision: "In Support",
		creditCommitteeDecision: "In Support",
		salesRationale: "",
		creditRationale: `In support of loan 2 (amount and duration to be agreed) - the total need for cash is R180,000. 
I suggest a co-funding of about 50% of it provided she can show proof of availability of funds for the balance. 
The loan should include monthly repayments and a large bullet at 90 days once she has taken full delivery of the machinery, 
produced and delivered the first order.

Ability to repay: business is still subject to volatility of revenues as production is based in Asia and the 
lead time to produce and import impacts her ability to scale her sales. Her revenues are fairly concentrated on one revenue 
stream (sales of papers) with 4+ clients. For a loan of R90,000 structured in 2 small instalments and one larger bullet, 
the affordability is sufficient for each instalment (Gross Ops Profit DSCR >1.5 at 3 months and 12 months) and acceptable for 
the bullet (DSCR Gross Ops Profit of 1.4).

Propensity to repay: repaid first 90% of first loan 2 days ahead of time and the balance about 4 days later.`,
		creditCommitteeRationale: `In support - I propose 2 possible products. As noted, a 50% cofund of the equipment with our current price or 70% with reduced interest rate if we use the piece of equipment as collateral? 
(our first asset finance product). There is risk on our part in the second option because we don’t know the value of the product & won’t know what a second-hand product would sell for - we could do this analysis after the deal so as to not delay it. 
I have ChatGPT’d a contract here (saved in client folder).`,
	});
	const handleChange = (field: keyof DecisionState, value: string) => {
		setDecisions((prev) => ({ ...prev, [field]: value }));
	};

	const scoreColors: Record<number, string> = {
		1: "#E57373", // Red (Low Score)
		2: "#FBC02D", // Amber (Medium-Low)
		3: "#FFD700", // Yellow (Medium-High)
		4: "#A7E0A7", // Green (High Score)
	};

	const [honestyAnswers, setHonestyAnswers] = useState<Record<string, string>>({
		estimatedRevenue: "Estimate greater than Actual Revenue + 100%",
		numEmployees: "Reports inaccurately - with up to ±2 FTE",
	});

	const handleHonestyChange = (event: any, field: string) => {
		setHonestyAnswers({ ...honestyAnswers, [field]: event.target.value });
	};

	const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
	};

	const [scores, setScores] = useState<Record<string, string>>({
		loanArrears: "3",
		expenseRatio: "1",
		staffPayment: "2",
		dataRequestResp: "4",
		insuranceSub: "1",
		accountingSoftUse: "1",
		cipcCompliance: "2",
		proactiveFlagging: "1",
		bizAvgDebtRatio: "2",
		avgHistoricalCurrRatio: "2",
		dscrOperatingProfit12Months: "3",
		dscrDispIncome12Months: "4",
		dscrOperatingProfit3Months: "2",
		dscrDispIncome3Months: "1",
		dscrOperatingProfitContractOrBullet: "3",
		dscrOperatingProfitContractOrTotal: "2",
		riskContractOrPurchaseOrder: "2",
		riskCustomerConcentration: "1",
		riskDepositFreq: "2",
		riskRevenueVolatility: "3",
		personalContrInvoices: "2",
		collectionRisk: "2",
		cession: "1",
		bizAssetAsCollateral: "1",
		personalSurety: "1",
	});

	const handleScoreChange = (field: string, value: string) => {
		setScores((prevScores) => ({
			...prevScores,
			[field]: value,
		}));
	};

	const [dscrTarget, setDscrTarget] = useState("1.5");

	const handleDscrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDscrTarget(event.target.value);
	};

	const getSelectedScoresTotal = (
		scores: Record<string, string>,
		selectedFields: { id: string; target: number }[]
	) => {
		return selectedFields.reduce(
			(acc, { id }) => acc + (parseInt(scores[id]) || 0),
			0
		);
	};

	// State for loan details
	const [loanDetails, setLoanDetails] = useState({
		amountRequested: 50000,
		duration: 90,
		interestRate: 0.2, // 0.20% per day
		originationFeePercent: 0,
	});

	// Computed values
	const totalInterestCharge = Math.round(
		loanDetails.amountRequested *
			loanDetails.interestRate *
			loanDetails.duration
	);
	const bulletPayment = loanDetails.amountRequested + totalInterestCharge;
	const originationFee =
		(loanDetails.originationFeePercent / 100) * loanDetails.amountRequested;
	const monthlyInstallment = Math.round(
		bulletPayment / (loanDetails.duration / 30)
	);

	// DSCR values (Example values)
	const dscrValues = [
		{
			label: "DSCR - Operating Profit Based",
			value: 6.2,
			targetDescription: "Based on: DSCR - Operating Profit Based",
			targetValue: "23,272",
		},
		{
			label: "DSCR - Net Operating Profit Based",
			value: 1.4,
			targetDescription: "Based on: DSCR - Net Operating Profit Based",
			targetValue: "5,156",
		},
		{
			label: "DSCR - Net CF Profit Based",
			value: 0.0,
			targetDescription: "Based on: DSCR - Net CF Profit Based",
			targetValue: null,
		},
		{
			label: "DSCR - Avg. Bank Balance",
			value: 4.7,
			targetDescription: "Based on: DSCR - Avg. Bank Balance",
			targetValue: "17,514",
		},
		{
			label: "DSCR - Disp. Income",
			value: 3.3,
			targetDescription: "Based on: DSCR - Disp. Income",
			targetValue: "12,292",
		},
	];

	// Function to determine DSCR color
	const getDscrColor = (value: number | null) => {
		if (value === null || value === 0) return "#E57373"; // Red
		if (value < 1.5) return "#FFB74D"; // Amber
		return "#81C784"; // Green
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100vw",
					height: "100vh",
					backgroundColor:
						theme.palette.mode == "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					position: "relative",
					top: "0%",
					overflowY: "auto",
				}}
			>
				<Box
					mb={2}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h6"></Typography>
					<IconButton edge="end" color="inherit" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>

				{/* Tabs for switching between views */}
				<Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
					<Tab label="Flag Rules" />
					<Tab label="Affordability Analysis" />
					<Tab label="Scorecard - Input" />
					<Tab label="Scorecard - Output" />
					<Tab label="Overview" />
				</Tabs>

				{/* Flags */}
				{activeTab === 0 && (
					<Card sx={{ marginTop: 2 }}>
						<CardContent>
							<Box
								sx={{
									maxWidth: "100%",
									p: 2,
									bgcolor: "#F0F4F8",
									borderRadius: 2,
									boxShadow: 2,
								}}
							>
								<Typography
									variant="h6"
									sx={{
										p: 2,
										backgroundColor: "#003f5c",
										color: "white",
										textAlign: "center",
									}}
								>
									Flag Rules{" "}
								</Typography>

								{/* Section: Qualifying Criteria */}
								<Box sx={{ mt: 2, bgcolor: "#AFC7D2", p: 1, borderRadius: 1 }}>
									<Typography variant="subtitle1">
										1. Qualifying Criteria
									</Typography>
								</Box>

								{[
									{
										label: "1.1 CIPC Compliance",
										severity: "green",
										value: "OK",
									},
									{
										label: "1.2 Revenue",
										severity: "amber",
										value: "R741,512",
									},
								].map((item, index) => (
									<Grid
										key={index}
										container
										alignItems="center"
										spacing={2}
										sx={{ mt: 1 }}
									>
										<Grid item xs={6}>
											<Typography>{item.label}</Typography>
										</Grid>
										<Grid item xs={5}>
											<TextField
												fullWidth
												variant="outlined"
												defaultValue={item.value}
											/>
										</Grid>
										<Grid item xs={1}>
											<Box
												sx={{
													bgcolor: severityColors[item.severity],
													width: 80,
													height: 40,
													borderRadius: 1,
												}}
											/>
										</Grid>
									</Grid>
								))}

								{/* Section: Character */}
								<Box sx={{ mt: 2, bgcolor: "#7D98A1", p: 1, borderRadius: 1 }}>
									<Typography variant="subtitle1">2. Character</Typography>
								</Box>

								<Box>
									{Object.entries(questionSections).map(
										([section, questions]) => (
											<Box key={section} sx={{ mt: 2 }}>
												<Box sx={{ bgcolor: "#D5DDE0", p: 1, borderRadius: 1 }}>
													<Typography
														variant="subtitle2"
														sx={{ fontWeight: "bold" }}
													>{`2.${section.charAt(0).toUpperCase()}${section.slice(1)}`}</Typography>
												</Box>
												{questions.map((item, index) => (
													<Grid
														key={index}
														container
														alignItems="center"
														spacing={2}
														sx={{ mt: 1 }}
													>
														<Grid item xs={6}>
															<Typography>{item.label}</Typography>
														</Grid>
														<Grid item xs={5}>
															{item.options ? (
																<FormControl fullWidth>
																	<Select
																		value={answers[item.field]}
																		onChange={(e) =>
																			setAnswers((prev) => ({
																				...prev,
																				[item.field]: e.target.value,
																			}))
																		}
																	>
																		{item.options?.map((option, idx) => (
																			<MenuItem key={idx} value={option.value}>
																				{option.value}
																			</MenuItem>
																		))}
																	</Select>
																</FormControl>
															) : (
																<TextField
																	fullWidth
																	type="number"
																	variant="outlined"
																	value={answers[item.field]}
																	onChange={(e) =>
																		setAnswers((prev) => ({
																			...prev,
																			[item.field]: e.target.value,
																		}))
																	}
																/>
															)}
														</Grid>
														<Grid item xs={1}>
															<Box
																sx={{
																	bgcolor: getSeverityColor(
																		item.field,
																		answers[item.field]
																	),
																	width: 80,
																	height: 40,
																	borderRadius: 1,
																}}
															/>
														</Grid>
													</Grid>
												))}
											</Box>
										)
									)}
								</Box>
							</Box>
						</CardContent>
					</Card>
				)}

				{/* Affordability Analysis */}
				{activeTab === 1 && (
					<Paper sx={{ padding: 3, maxWidth: 800, margin: "auto", mt: 4 }}>
						<Typography
							variant="h6"
							sx={{
								p: 2,
								backgroundColor: "#003f5c",
								color: "white",
								textAlign: "center",
							}}
						>
							Debt Affordability Analysis{" "}
						</Typography>

						<Box
							p={2}
							borderRadius={1}
							sx={{ mt: 3, backgroundColor: "#c1d5e0" }}
						>
							<Typography variant="h6">Loan Details</Typography>
						</Box>

						<Grid container spacing={2} sx={{ mt: 2 }}>
							{/* Loan Details Fields */}
							{[
								{
									label: "Amount Requested",
									key: "amountRequested",
									symbol: "R",
								},
								{ label: "Duration", key: "duration", symbol: "Days" },
								{
									label: "Interest Rate (per day)",
									key: "interestRate",
									symbol: "%",
								},
								// {
								//   label: "Origination Fee (%)",
								//   key: "originationFeePercent",
								//   symbol: "%",
								// },
							].map(({ label, key, symbol }) => (
								<Grid item xs={6} key={key}>
									<TextField
										fullWidth
										label={label}
										variant="outlined"
										type="number"
										value={loanDetails[key as keyof typeof loanDetails]}
										onChange={(e) =>
											setLoanDetails({
												...loanDetails,
												[key]: parseFloat(e.target.value) || 0,
											})
										}
										InputProps={{
											endAdornment: (
												<Typography sx={{ ml: 1 }}>{symbol}</Typography>
											),
										}}
									/>
								</Grid>
							))}

							{/* Computed Fields */}
							{[
								{
									label: "Origination Fee",
									value: originationFee,
									symbol: "R",
								},
								// {
								//   label: "Total Interest Charge",
								//   value: totalInterestCharge,
								//   symbol: "R",
								// },
								// { label: "Bullet Payment", value: bulletPayment, symbol: "R" },
								// {
								//   label: "Monthly Installment",
								//   value: monthlyInstallment,
								//   symbol: "R",
								// },
							].map(({ label, value, symbol }) => (
								<Grid item xs={6} key={label}>
									<TextField
										fullWidth
										label={label}
										variant="outlined"
										value={value.toLocaleString()}
										InputProps={{
											readOnly: true,
											endAdornment: (
												<Typography sx={{ ml: 1 }}>{symbol}</Typography>
											),
										}}
									/>
								</Grid>
							))}
						</Grid>

						<Box
							p={2}
							borderRadius={1}
							sx={{ mt: 3, backgroundColor: "#c1d5e0" }}
						>
							<Typography variant="h6">
								Affordability - Based on Historical Performance
							</Typography>
						</Box>

						<Box
							p={2}
							borderRadius={1}
							sx={{ mt: 3, backgroundColor: "#f5f5f5" }}
						>
							<Typography variant="subtitle1">
								Monthly Installment Affordability
							</Typography>
						</Box>

						<TableContainer component={Paper} sx={{ mt: 2 }}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell>DSCR (12-months)</TableCell>
										<TableCell align="center"></TableCell>
										<TableCell align="left">DSCR Target of</TableCell>
										<TableCell align="right">
											<TextField
												variant="outlined"
												value={dscrTarget}
												onChange={handleDscrChange}
												InputProps={{
													style: {
														textAlign: "right",
														backgroundColor: "#f5f5f5", // Subtle background color
														borderRadius: "4px",
														padding: "4px 8px",
													},
												}}
												sx={{
													width: "80px", // Adjust width as needed
													"& .MuiOutlinedInput-root": {
														"& fieldset": {
															border: "none", // Remove default border
														},
														"&:hover fieldset": {
															border: "1px solid #bdbdbd", // Subtle border on hover
														},
														"&.Mui-focused fieldset": {
															border: "1px solid #3f51b5", // Focus border
														},
													},
												}}
											/>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{dscrValues.map((dscr) => (
										<TableRow key={dscr.label}>
											<TableCell>{dscr.label}</TableCell>
											<TableCell
												align="center"
												sx={{ bgcolor: getDscrColor(dscr.value) }}
											>
												{dscr.value !== null ? dscr.value.toFixed(1) : "N.E."}
											</TableCell>
											<TableCell align="left">
												{dscr.targetDescription !== null
													? `${dscr.targetDescription}`
													: "N.E."}
											</TableCell>
											<TableCell align="right">
												{dscr.targetValue !== null
													? `R${dscr.targetValue.toLocaleString()}`
													: "N.E."}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				)}

				{/* Scorecard - Input*/}
				{activeTab === 2 && (
					<Card sx={{ marginTop: 2 }}>
						<CardContent>
							<TableContainer
								component={Paper}
								sx={{
									border: "1px solid #003f5c",
									borderRadius: 2,
									overflow: "hidden",
								}}
							>
								<Typography
									variant="h6"
									sx={{
										p: 2,
										backgroundColor: "#003f5c",
										color: "white",
										textAlign: "center",
									}}
								>
									Credit Risk Scorecard
								</Typography>
								<Table>
									<TableHead>
										<TableRow sx={{ backgroundColor: "#c1d5e0" }}>
											<TableCell>
												<strong>Credit Risk Dimension</strong>
											</TableCell>
											<TableCell>
												<strong>Score</strong>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/* Character */}
										<TableRow>
											<TableCell colSpan={2} sx={{ padding: 0 }}>
												<Accordion defaultExpanded={false}>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														sx={{ backgroundColor: "#f5f5f5" }}
													>
														<Typography sx={{ fontWeight: "bold" }}>
															1. Character
														</Typography>
													</AccordionSummary>
													<AccordionDetails sx={{ padding: 0 }}>
														<Table>
															<TableBody>
																<TableRow>
																	<TableCell
																		colSpan={2}
																		sx={{
																			backgroundColor: "#e3ebf0",
																			fontWeight: "bold",
																		}}
																	>
																		1.1 Responsibility
																	</TableCell>
																</TableRow>
																{[
																	{
																		id: "loanArrears",
																		question:
																			"1.1.1 What is the total value of personal loan accounts in arrears?",
																		options: [
																			{ value: "None", weight: 4 },
																			{ value: "<10,000 Rands", weight: 3 },
																			{
																				value:
																					"Between 10,000 Rands and 100,000 Rands",
																				weight: 2,
																			},
																			{ value: ">100,000 Rands", weight: 1 },
																		],
																	},
																	{
																		id: "expenseRatio",
																		question:
																			"1.1.2 What is the ratio of personal expenses in account over the last 3 months?",
																		options: [
																			{ value: "0%", weight: 4 },
																			{ value: "Between 0 and 2%", weight: 3 },
																			{ value: "Between 2% and 5%", weight: 2 },
																			{ value: "Over 5%", weight: 1 },
																		],
																	},
																	{
																		id: "staffPayment",
																		question:
																			"1.1.3 Do they pay their staff consistently on time?",
																		options: [
																			{ value: "100% of the time", weight: 4 },
																			{ value: "95% of the time", weight: 3 },
																			{ value: "85% of the time", weight: 2 },
																			{
																				value: "Less than 85% of the time",
																				weight: 1,
																			},
																		],
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>
																			<Select
																				value={scores[item.id] || ""}
																				onChange={(e) =>
																					handleScoreChange(
																						item.id,
																						e.target.value
																					)
																				}
																				variant="outlined"
																				size="small"
																				fullWidth
																			>
																				<MenuItem value="">
																					<em>Select an option (0)</em>
																				</MenuItem>
																				{item.options.map((option, index) => (
																					<MenuItem
																						key={index}
																						value={option.weight}
																					>
																						<Box
																							sx={{
																								display: "inline-block",
																								backgroundColor:
																									scoreColors[option.weight],
																								color: "black",
																								fontWeight: "bold",
																								padding: "2px 6px",
																								borderRadius: "4px",
																								marginRight: "8px",
																							}}
																						>
																							{option.weight}
																						</Box>
																						{option.value}
																					</MenuItem>
																				))}
																			</Select>
																		</TableCell>
																	</TableRow>
																))}

																<TableRow>
																	<TableCell
																		colSpan={2}
																		sx={{
																			backgroundColor: "#e3ebf0",
																			fontWeight: "bold",
																		}}
																	>
																		1.2 Discipline
																	</TableCell>
																</TableRow>
																{[
																	{
																		id: "dataRequestResp",
																		question:
																			"1.2.1 How responsive are they to a data request or question?",
																		options: [
																			{
																				value: "Less than 1 business day",
																				weight: 4,
																			},
																			{
																				value: "Less than 2 business days",
																				weight: 3,
																			},
																			{
																				value: "Less than 5 business days",
																				weight: 2,
																			},
																			{
																				value: "More than 5 business days",
																				weight: 1,
																			},
																		],
																	},
																	{
																		id: "insuranceSub",
																		question:
																			"1.2.2 Have they subscribed to an insurance product?",
																		options: [
																			{ value: "Yes", weight: 4 },
																			{ value: "", weight: 3 },
																			{ value: "", weight: 2 },
																			{ value: "No", weight: 1 },
																		],
																	},
																	{
																		id: "accountingSoftUse",
																		question:
																			"1.2.3 Are they actively using an accounting software or have engaged an accountant?",
																		options: [
																			{ value: "Yes - accountant", weight: 4 },
																			{ value: "Yes - bookkeeper", weight: 3 },
																			{
																				value:
																					"Yes - accounting software but no accountant or bookkeeper",
																				weight: 2,
																			},
																			{ value: "No", weight: 1 },
																		],
																	},
																	{
																		id: "cipcCompliance",
																		question:
																			"1.2.4 Do they maintain a compliant status?",
																		options: [
																			{
																				value:
																					"SARS Tax Pin provided and in order + CIPC compliant",
																				weight: 4,
																			},
																			{
																				value:
																					"SARS Tax Pin provided but expired + CIPC compliant",
																				weight: 3,
																			},
																			{ value: "CIPC compliant", weight: 2 },
																			{
																				value: "CIPC A/R De-registration",
																				weight: 1,
																			},
																		],
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>
																			<Select
																				value={scores[item.id] || ""}
																				onChange={(e) =>
																					handleScoreChange(
																						item.id,
																						e.target.value
																					)
																				}
																				variant="outlined"
																				size="small"
																				fullWidth
																			>
																				<MenuItem value="">
																					<em>Select an option (0)</em>
																				</MenuItem>
																				{item.options.map((option, index) => (
																					<MenuItem
																						key={index}
																						value={option.weight}
																					>
																						<Box
																							sx={{
																								display: "inline-block",
																								backgroundColor:
																									scoreColors[option.weight],
																								color: "black",
																								fontWeight: "bold",
																								padding: "2px 6px",
																								borderRadius: "4px",
																								marginRight: "8px",
																							}}
																						>
																							{option.weight}
																						</Box>
																						{option.value}
																					</MenuItem>
																				))}
																			</Select>
																		</TableCell>
																	</TableRow>
																))}

																<TableRow>
																	<TableCell
																		colSpan={2}
																		sx={{
																			backgroundColor: "#e3ebf0",
																			fontWeight: "bold",
																		}}
																	>
																		1.3 Resilience
																	</TableCell>
																</TableRow>
																{[
																	{
																		id: "proactiveFlagging",
																		question:
																			"1.3.1 Are they proactively flagging and proposing a resolution to payment issue?",
																		options: [
																			{
																				value:
																					"No late payment or flagging 5 or more business days ahead",
																				weight: 4,
																			},
																			{
																				value:
																					"Flagging between 5 to 1 business days ahead",
																				weight: 3,
																			},
																			{
																				value:
																					"Flagging between 1 to 0 business days ahead",
																				weight: 2,
																			},
																			{ value: "No flagging", weight: 1 },
																		],
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>
																			<Select
																				value={scores[item.id] || ""}
																				onChange={(e) =>
																					handleScoreChange(
																						item.id,
																						e.target.value
																					)
																				}
																				variant="outlined"
																				size="small"
																				fullWidth
																			>
																				<MenuItem value="">
																					<em>Select an option (0)</em>
																				</MenuItem>
																				{item.options.map((option, index) => (
																					<MenuItem
																						key={index}
																						value={option.weight}
																					>
																						<Box
																							sx={{
																								display: "inline-block",
																								backgroundColor:
																									scoreColors[option.weight],
																								color: "black",
																								fontWeight: "bold",
																								padding: "2px 6px",
																								borderRadius: "4px",
																								marginRight: "8px",
																							}}
																						>
																							{option.weight}
																						</Box>
																						{option.value}
																					</MenuItem>
																				))}
																			</Select>
																		</TableCell>
																	</TableRow>
																))}
															</TableBody>
														</Table>
													</AccordionDetails>
												</Accordion>
											</TableCell>
										</TableRow>

										{/* Capacity */}
										<TableRow>
											<TableCell colSpan={2} sx={{ padding: 0 }}>
												<Accordion defaultExpanded={false}>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														sx={{ backgroundColor: "#f5f5f5" }}
													>
														<Typography sx={{ fontWeight: "bold" }}>
															2. Capacity
														</Typography>
													</AccordionSummary>
													<AccordionDetails sx={{ padding: 0 }}>
														<Table>
															<TableBody>
																<TableRow>
																	<TableCell
																		colSpan={2}
																		sx={{
																			backgroundColor: "#e3ebf0",
																			fontWeight: "bold",
																		}}
																	>
																		2.1 Affordability - Historical Performance
																	</TableCell>
																</TableRow>
																{[
																	{
																		id: "bizAvgDebtRatio",
																		question:
																			"2.1.1 How indebted is the business? Average Historical Debt ratio",
																		options: [
																			{ value: "0-16%", weight: 4 },
																			{ value: "16.01%-27%", weight: 3 },
																			{ value: "27.01%-45%", weight: 2 },
																			{ value: "45.01%+", weight: 1 },
																		],
																	},
																	{
																		id: "avgHistoricalCurrRatio",
																		question:
																			"2.1.2 What is the business's ability to serve its short-term liabilities? Average Historical Current ratio",
																		options: [
																			{ value: ">2.4", weight: 4 },
																			{ value: "1.3-2.4", weight: 3 },
																			{ value: "0.9-1.29", weight: 2 },
																			{ value: "0.9-1.29", weight: 1 },
																		],
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>
																			<Select
																				value={scores[item.id] || ""}
																				onChange={(e) =>
																					handleScoreChange(
																						item.id,
																						e.target.value
																					)
																				}
																				variant="outlined"
																				size="small"
																				fullWidth
																			>
																				<MenuItem value="">
																					<em>Select an option (0)</em>
																				</MenuItem>
																				{item.options.map((option, index) => (
																					<MenuItem
																						key={index}
																						value={option.weight}
																					>
																						<Box
																							sx={{
																								display: "inline-block",
																								backgroundColor:
																									scoreColors[option.weight],
																								color: "black",
																								fontWeight: "bold",
																								padding: "2px 6px",
																								borderRadius: "4px",
																								marginRight: "8px",
																							}}
																						>
																							{option.weight}
																						</Box>
																						{option.value}
																					</MenuItem>
																				))}
																			</Select>
																		</TableCell>
																	</TableRow>
																))}

																<TableRow>
																	<TableCell
																		colSpan={2}
																		sx={{
																			backgroundColor: "#e3ebf0",
																			fontWeight: "bold",
																		}}
																	>
																		2.2 Affordability - Monthly Payment
																	</TableCell>
																</TableRow>
																{[
																	{
																		id: "dscrOperatingProfit12Months",
																		question:
																			"2.2.1 DSCR - Operating Profit Based - 12 months average",
																		options: [
																			{ value: "1:1.5 +", weight: 4 },
																			{ value: "1:1.4 +", weight: 3 },
																			{ value: "1:1.3 +", weight: 2 },
																			{ value: "1:1.2 +", weight: 1 },
																		],
																	},
																	{
																		id: "dscrDispIncome12Months",
																		question:
																			"2.2.2 DSCR - Disp. Income - 12 months average",
																		options: [
																			{ value: "1:1.5 +", weight: 4 },
																			{ value: "1:1.4 +", weight: 3 },
																			{ value: "1:1.3 +", weight: 2 },
																			{ value: "1:1.2 +", weight: 1 },
																		],
																	},
																	{
																		id: "dscrOperatingProfit3Months",
																		question:
																			"2.2.3 DSCR - Operating Profit Based - 3 months average",
																		options: [
																			{ value: "1:1.5 +", weight: 4 },
																			{ value: "1:1.4 +", weight: 3 },
																			{ value: "1:1.3 +", weight: 2 },
																			{ value: "1:1.2 +", weight: 1 },
																		],
																	},
																	{
																		id: "dscrDispIncome3Months",
																		question:
																			"2.2.4 DSCR - Disp. Income - 3 months average",
																		options: [
																			{ value: "1:1.5 +", weight: 4 },
																			{ value: "1:1.4 +", weight: 3 },
																			{ value: "1:1.3 +", weight: 2 },
																			{ value: "1:1.2 +", weight: 1 },
																		],
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>
																			<Select
																				value={scores[item.id] || ""}
																				onChange={(e) =>
																					handleScoreChange(
																						item.id,
																						e.target.value
																					)
																				}
																				variant="outlined"
																				size="small"
																				fullWidth
																			>
																				<MenuItem value="">
																					<em>Select an option (0)</em>
																				</MenuItem>
																				{item.options.map((option, index) => (
																					<MenuItem
																						key={index}
																						value={option.weight}
																					>
																						<Box
																							sx={{
																								display: "inline-block",
																								backgroundColor:
																									scoreColors[option.weight],
																								color: "black",
																								fontWeight: "bold",
																								padding: "2px 6px",
																								borderRadius: "4px",
																								marginRight: "8px",
																							}}
																						>
																							{option.weight}
																						</Box>
																						{option.value}
																					</MenuItem>
																				))}
																			</Select>
																		</TableCell>
																	</TableRow>
																))}

																<TableRow>
																	<TableCell
																		colSpan={2}
																		sx={{
																			backgroundColor: "#e3ebf0",
																			fontWeight: "bold",
																		}}
																	>
																		2.3 Affordability - Bullet Payment (for
																		PO/Contract Finance only)
																	</TableCell>
																</TableRow>
																{[
																	{
																		id: "dscrOperatingProfitContractOrBullet",
																		question:
																			"2.3.1 DSCR - Operating Profit Based on Contract (Bullet)",
																		options: [
																			{ value: "1:1.5 +", weight: 4 },
																			{ value: "1:1.4 +", weight: 3 },
																			{ value: "1:1.3 +", weight: 2 },
																			{ value: "1:1.2 +", weight: 1 },
																		],
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>
																			<Select
																				value={scores[item.id] || ""}
																				onChange={(e) =>
																					handleScoreChange(
																						item.id,
																						e.target.value
																					)
																				}
																				variant="outlined"
																				size="small"
																				fullWidth
																			>
																				<MenuItem value="">
																					<em>Select an option (0)</em>
																				</MenuItem>
																				{item.options.map((option, index) => (
																					<MenuItem
																						key={index}
																						value={option.weight}
																					>
																						<Box
																							sx={{
																								display: "inline-block",
																								backgroundColor:
																									scoreColors[option.weight],
																								color: "black",
																								fontWeight: "bold",
																								padding: "2px 6px",
																								borderRadius: "4px",
																								marginRight: "8px",
																							}}
																						>
																							{option.weight}
																						</Box>
																						{option.value}
																					</MenuItem>
																				))}
																			</Select>
																		</TableCell>
																	</TableRow>
																))}

																<TableRow>
																	<TableCell
																		colSpan={2}
																		sx={{
																			backgroundColor: "#e3ebf0",
																			fontWeight: "bold",
																		}}
																	>
																		2.4 Affordability - Total Payment (for
																		PO/Contract Finance only)
																	</TableCell>
																</TableRow>
																{[
																	{
																		id: "dscrOperatingProfitContractOrTotal",
																		question:
																			"2.4.1 DSCR - Operating Profit Based on Contract (Total)",
																		options: [
																			{ value: "1:1.5 +", weight: 4 },
																			{ value: "1:1.4 +", weight: 3 },
																			{ value: "1:1.3 +", weight: 2 },
																			{ value: "1:1.2 +", weight: 1 },
																		],
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>
																			<Select
																				value={scores[item.id] || ""}
																				onChange={(e) =>
																					handleScoreChange(
																						item.id,
																						e.target.value
																					)
																				}
																				variant="outlined"
																				size="small"
																				fullWidth
																			>
																				<MenuItem value="">
																					<em>Select an option (0)</em>
																				</MenuItem>
																				{item.options.map((option, index) => (
																					<MenuItem
																						key={index}
																						value={option.weight}
																					>
																						<Box
																							sx={{
																								display: "inline-block",
																								backgroundColor:
																									scoreColors[option.weight],
																								color: "black",
																								fontWeight: "bold",
																								padding: "2px 6px",
																								borderRadius: "4px",
																								marginRight: "8px",
																							}}
																						>
																							{option.weight}
																						</Box>
																						{option.value}
																					</MenuItem>
																				))}
																			</Select>
																		</TableCell>
																	</TableRow>
																))}

																<TableRow>
																	<TableCell
																		colSpan={2}
																		sx={{
																			backgroundColor: "#e3ebf0",
																			fontWeight: "bold",
																		}}
																	>
																		2.5 Risks to Revenue
																	</TableCell>
																</TableRow>
																{[
																	{
																		id: "riskContractOrPurchaseOrder",
																		question:
																			"2.5.1 Contract or Purchase Order",
																		options: [
																			{
																				value:
																					"Verified contract and/or firm purchase order - with proven past payment",
																				weight: 4,
																			},
																			{
																				value:
																					"Verified contract and/or firm purchase order - with no (proven) past payment",
																				weight: 3,
																			},
																			{
																				value:
																					"Non-commitment contract (price or volume)",
																				weight: 2,
																			},
																			{
																				value:
																					"No contract or PO, but established history of sales and proven & verified payment",
																				weight: 1,
																			},
																		],
																	},
																	{
																		id: "riskCustomerConcentration",
																		question: "2.5.2 Customer concentration",
																		options: [
																			{ value: "> 10 Customers", weight: 4 },
																			{ value: "6 - 9 Customers", weight: 3 },
																			{ value: "3 - 5 Customers", weight: 2 },
																			{
																				value: "less than 3 Customers",
																				weight: 1,
																			},
																		],
																	},
																	{
																		id: "riskDepositFreq",
																		question: "2.5.3 Frequency of deposits",
																		options: [
																			{ value: "10+ per week", weight: 4 },
																			{ value: "5 to 10 per week", weight: 3 },
																			{ value: "2 to 5 per week", weight: 2 },
																			{ value: "1 to 2 per week", weight: 1 },
																		],
																	},
																	{
																		id: "riskRevenueVolatility",
																		question:
																			"2.5.4 Revenue Volatility - Monthly revenue std. deviation (%)",
																		options: [
																			{ value: "Less than 25%", weight: 4 },
																			{
																				value: "Between 25% and 50%",
																				weight: 3,
																			},
																			{
																				value: "Between 50% and 100%",
																				weight: 2,
																			},
																			{ value: "Over 100%", weight: 1 },
																		],
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>
																			<Select
																				value={scores[item.id] || ""}
																				onChange={(e) =>
																					handleScoreChange(
																						item.id,
																						e.target.value
																					)
																				}
																				variant="outlined"
																				size="small"
																				fullWidth
																			>
																				<MenuItem value="">
																					<em>Select an option (0)</em>
																				</MenuItem>
																				{item.options.map((option, index) => (
																					<MenuItem
																						key={index}
																						value={option.weight}
																					>
																						<Box
																							sx={{
																								display: "inline-block",
																								backgroundColor:
																									scoreColors[option.weight],
																								color: "black",
																								fontWeight: "bold",
																								padding: "2px 6px",
																								borderRadius: "4px",
																								marginRight: "8px",
																							}}
																						>
																							{option.weight}
																						</Box>
																						{option.value}
																					</MenuItem>
																				))}
																			</Select>
																		</TableCell>
																	</TableRow>
																))}
															</TableBody>
														</Table>
													</AccordionDetails>
												</Accordion>
											</TableCell>
										</TableRow>

										{/* Collateral */}
										<TableRow>
											<TableCell colSpan={2} sx={{ padding: 0 }}>
												<Accordion defaultExpanded={false}>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														sx={{ backgroundColor: "#f5f5f5" }}
													>
														<Typography sx={{ fontWeight: "bold" }}>
															3. Collateral
														</Typography>
													</AccordionSummary>
													<AccordionDetails sx={{ padding: 0 }}>
														<Table>
															<TableBody>
																<TableRow>
																	<TableCell
																		colSpan={2}
																		sx={{
																			backgroundColor: "#e3ebf0",
																			fontWeight: "bold",
																		}}
																	></TableCell>
																</TableRow>
																{[
																	{
																		id: "cession",
																		question: "3.1 Cession",
																		options: [
																			{
																				value:
																					"Signed cession + full control of bank account",
																				weight: 4,
																			},
																			{
																				value:
																					"Signed cession + visibility on account",
																				weight: 3,
																			},
																			{ value: "Signed cession", weight: 2 },
																			{ value: "No cession", weight: 1 },
																		],
																	},
																	{
																		id: "bizAssetAsCollateral",
																		question:
																			"3.2 Business Asset as Collateral",
																		options: [
																			{
																				value:
																					"Attached asset with book value over 150% of total loan value",
																				weight: 4,
																			},
																			{
																				value:
																					"Attached asset with book value between 80% and 150% of total loan value",
																				weight: 3,
																			},
																			{
																				value:
																					"Attached asset with book value less than 80% of total loan value",
																				weight: 2,
																			},
																			{ value: "No attached asset", weight: 1 },
																		],
																	},
																	{
																		id: "personalSurety",
																		question:
																			"3.3 Personal Surety - Net Worth (Total Asset minus Total Liability)",
																		options: [
																			{
																				value:
																					"Personal surety - Net Worth over 150% of total loan value",
																				weight: 4,
																			},
																			{
																				value:
																					"Personal surety - Net Worth between 80% and 150% of total loan value",
																				weight: 3,
																			},
																			{
																				value:
																					"Personal surety - Net Worth less than 80% of total loan value",
																				weight: 2,
																			},
																			{
																				value: "No personal surety",
																				weight: 1,
																			},
																		],
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>
																			<Select
																				value={scores[item.id] || ""}
																				onChange={(e) =>
																					handleScoreChange(
																						item.id,
																						e.target.value
																					)
																				}
																				variant="outlined"
																				size="small"
																				fullWidth
																			>
																				<MenuItem value="">
																					<em>Select an option (0)</em>
																				</MenuItem>
																				{item.options.map((option, index) => (
																					<MenuItem
																						key={index}
																						value={option.weight}
																					>
																						<Box
																							sx={{
																								display: "inline-block",
																								backgroundColor:
																									scoreColors[option.weight],
																								color: "black",
																								fontWeight: "bold",
																								padding: "2px 6px",
																								borderRadius: "4px",
																								marginRight: "8px",
																							}}
																						>
																							{option.weight}
																						</Box>
																						{option.value}
																					</MenuItem>
																				))}
																			</Select>
																		</TableCell>
																	</TableRow>
																))}
															</TableBody>
														</Table>
													</AccordionDetails>
												</Accordion>
											</TableCell>
										</TableRow>

										{/* Capital */}
										<TableRow>
											<TableCell colSpan={2} sx={{ padding: 0 }}>
												<Accordion defaultExpanded={false}>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														sx={{ backgroundColor: "#f5f5f5" }}
													>
														<Typography sx={{ fontWeight: "bold" }}>
															4. Capital
														</Typography>
													</AccordionSummary>
													<AccordionDetails sx={{ padding: 0 }}>
														<Table>
															<TableBody>
																<TableRow>
																	<TableCell
																		colSpan={2}
																		sx={{
																			backgroundColor: "#e3ebf0",
																			fontWeight: "bold",
																		}}
																	></TableCell>
																</TableRow>
																{[
																	{
																		id: "personalContrInvoices",
																		question:
																			"4.1 Personal contribution to invoice(s) (% of total amount)",
																		options: [
																			{ value: "50%", weight: 4 },
																			{ value: "25-50%", weight: 3 },
																			{ value: "10-25%", weight: 2 },
																			{ value: "0-10%", weight: 1 },
																		],
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>
																			<Select
																				value={scores[item.id] || ""}
																				onChange={(e) =>
																					handleScoreChange(
																						item.id,
																						e.target.value
																					)
																				}
																				variant="outlined"
																				size="small"
																				fullWidth
																			>
																				<MenuItem value="">
																					<em>Select an option (0)</em>
																				</MenuItem>
																				{item.options.map((option, index) => (
																					<MenuItem
																						key={index}
																						value={option.weight}
																					>
																						<Box
																							sx={{
																								display: "inline-block",
																								backgroundColor:
																									scoreColors[option.weight],
																								color: "black",
																								fontWeight: "bold",
																								padding: "2px 6px",
																								borderRadius: "4px",
																								marginRight: "8px",
																							}}
																						>
																							{option.weight}
																						</Box>
																						{option.value}
																					</MenuItem>
																				))}
																			</Select>
																		</TableCell>
																	</TableRow>
																))}
															</TableBody>
														</Table>
													</AccordionDetails>
												</Accordion>
											</TableCell>
										</TableRow>

										{/* Conditions */}
										<TableRow>
											<TableCell colSpan={2} sx={{ padding: 0 }}>
												<Accordion defaultExpanded={false}>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														sx={{ backgroundColor: "#f5f5f5" }}
													>
														<Typography sx={{ fontWeight: "bold" }}>
															5. Conditions
														</Typography>
													</AccordionSummary>
													<AccordionDetails sx={{ padding: 0 }}>
														<Table>
															<TableBody>
																<TableRow>
																	<TableCell
																		colSpan={2}
																		sx={{
																			backgroundColor: "#e3ebf0",
																			fontWeight: "bold",
																		}}
																	></TableCell>
																</TableRow>
																{[
																	{
																		id: "collectionRisk",
																		question: "5.1 Collection risk",
																		options: [
																			{
																				value:
																					"Off-taker agree to pay lender - cession",
																				weight: 4,
																			},
																			{ value: "Debit order", weight: 3 },
																			{ value: "Deposit", weight: 2 },
																			{ value: "", weight: 1 },
																		],
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>
																			<Select
																				value={scores[item.id] || ""}
																				onChange={(e) =>
																					handleScoreChange(
																						item.id,
																						e.target.value
																					)
																				}
																				variant="outlined"
																				size="small"
																				fullWidth
																			>
																				<MenuItem value="">
																					<em>Select an option (0)</em>
																				</MenuItem>
																				{item.options.map((option, index) => (
																					<MenuItem
																						key={index}
																						value={option.weight}
																					>
																						<Box
																							sx={{
																								display: "inline-block",
																								backgroundColor:
																									scoreColors[option.weight],
																								color: "black",
																								fontWeight: "bold",
																								padding: "2px 6px",
																								borderRadius: "4px",
																								marginRight: "8px",
																							}}
																						>
																							{option.weight}
																						</Box>
																						{option.value}
																					</MenuItem>
																				))}
																			</Select>
																		</TableCell>
																	</TableRow>
																))}
															</TableBody>
														</Table>
													</AccordionDetails>
												</Accordion>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</CardContent>
					</Card>
				)}

				{/* Scorecard - Output*/}
				{activeTab === 3 && (
					<Card sx={{ marginTop: 2 }}>
						<CardContent>
							<TableContainer
								component={Paper}
								sx={{
									border: "1px solid #003f5c",
									borderRadius: 2,
									overflow: "hidden",
								}}
							>
								<Typography
									variant="h6"
									sx={{
										p: 2,
										backgroundColor: "#003f5c",
										color: "white",
										textAlign: "center",
									}}
								>
									Credit Risk Scorecard Results
								</Typography>
								<Table>
									<TableHead>
										<TableRow sx={{ backgroundColor: "#c1d5e0" }}>
											<TableCell sx={{ width: "60%" }}>
												<strong>Credit Risk Dimension</strong>
											</TableCell>
											<TableCell sx={{ width: "20%" }}>
												<strong>Target</strong>
											</TableCell>
											<TableCell sx={{ width: "20%" }}>
												<strong>Score</strong>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/* Character */}
										<TableRow>
											<TableCell colSpan={3} sx={{ padding: 0 }}>
												<Accordion defaultExpanded={false}>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														sx={{ backgroundColor: "#f5f5f5" }}
													>
														<Typography sx={{ fontWeight: "bold" }}>
															1. Character
														</Typography>
													</AccordionSummary>
													<AccordionDetails sx={{ padding: 0 }}>
														<Table>
															<TableBody>
																{/* Responsibility */}
																<TableRow>
																	<TableCell
																		sx={{
																			width: "60%",
																			backgroundColor: "#003f5c",
																			fontWeight: "bold",
																			color: "white",
																		}}
																	>
																		1.1 Responsibility
																	</TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																</TableRow>
																{[
																	{
																		id: "loanArrears",
																		question:
																			"1.1.1 What is the total value of personal loan accounts in arrears?",
																		target: 4,
																		achieved:
																			Number(scores["loanArrears"]) || 0,
																	},
																	{
																		id: "expenseRatio",
																		question:
																			"1.1.2 What is the ratio of personal expenses in account over the last 3 months?",
																		target: 3,
																		achieved:
																			Number(scores["expenseRatio"]) || 0,
																	},
																	{
																		id: "staffPayment",
																		question:
																			"1.1.3 Do they pay their staff consistently on time?",
																		target: 3,
																		achieved:
																			Number(scores["staffPayment"]) || 0,
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>{item.target}</TableCell>
																		<TableCell>
																			<Box
																				sx={{
																					display: "inline-block",
																					backgroundColor:
																						scoreColors[item.achieved],
																					color: "black",
																					fontWeight: "bold",
																					padding: "2px 6px",
																					borderRadius: "4px",
																				}}
																			>
																				{item.achieved}
																			</Box>
																		</TableCell>
																	</TableRow>
																))}

																{/* Discipline */}
																<TableRow>
																	<TableCell
																		sx={{
																			width: "60%",
																			backgroundColor: "#003f5c",
																			fontWeight: "bold",
																			color: "white",
																		}}
																	>
																		1.2 Discipline
																	</TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																</TableRow>
																{[
																	{
																		id: "dataRequestResp",
																		question:
																			"1.2.1 How responsive are they to a data request or question?",
																		target: 4,
																		achieved:
																			Number(scores["loanArrears"]) || 0,
																	},
																	{
																		id: "insuranceSub",
																		question:
																			"1.2.2 Have they subscribed to an insurance product?",
																		target: 3,
																		achieved:
																			Number(scores["expenseRatio"]) || 0,
																	},
																	{
																		id: "accountingSoftUse",
																		question:
																			"1.2.3 Are they actively using an accounting software or have engaged an accountant?",
																		target: 3,
																		achieved:
																			Number(scores["staffPayment"]) || 0,
																	},
																	{
																		id: "cipcCompliance",
																		question:
																			"1.2.4 Do they maintain a compliant status?",
																		target: 3,
																		achieved:
																			Number(scores["staffPayment"]) || 0,
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>{item.target}</TableCell>
																		<TableCell>
																			<Box
																				sx={{
																					display: "inline-block",
																					backgroundColor:
																						scoreColors[item.achieved],
																					color: "black",
																					fontWeight: "bold",
																					padding: "2px 6px",
																					borderRadius: "4px",
																				}}
																			>
																				{item.achieved}
																			</Box>
																		</TableCell>
																	</TableRow>
																))}

																{/* Resilience */}
																<TableRow>
																	<TableCell
																		sx={{
																			width: "60%",
																			backgroundColor: "#003f5c",
																			fontWeight: "bold",
																			color: "white",
																		}}
																	>
																		1.3 Resilience
																	</TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																</TableRow>
																{[
																	{
																		id: "proactiveFlagging",
																		question:
																			"1.3.1 Are they proactively flagging and proposing a resolution to payment issue?",
																		target: 4,
																		achieved:
																			Number(scores["proactiveFlagging"]) || 0,
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>{item.target}</TableCell>
																		<TableCell>
																			<Box
																				sx={{
																					display: "inline-block",
																					backgroundColor:
																						scoreColors[item.achieved],
																					color: "black",
																					fontWeight: "bold",
																					padding: "2px 6px",
																					borderRadius: "4px",
																				}}
																			>
																				{item.achieved}
																			</Box>
																		</TableCell>
																	</TableRow>
																))}
															</TableBody>
														</Table>
													</AccordionDetails>
												</Accordion>
											</TableCell>
										</TableRow>

										{/* Capacity */}
										<TableRow>
											<TableCell colSpan={3} sx={{ padding: 0 }}>
												<Accordion defaultExpanded={false}>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														sx={{ backgroundColor: "#f5f5f5" }}
													>
														<Typography sx={{ fontWeight: "bold" }}>
															2. Capacity
														</Typography>
													</AccordionSummary>
													<AccordionDetails sx={{ padding: 0 }}>
														<Table>
															<TableBody>
																{/* Affordability - Historical Performance */}
																<TableRow>
																	<TableCell
																		sx={{
																			width: "60%",
																			backgroundColor: "#003f5c",
																			fontWeight: "bold",
																			color: "white",
																		}}
																	>
																		2.1 Affordability - Historical Performance
																	</TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																</TableRow>
																{[
																	{
																		id: "bizAvgDebtRatio",
																		question:
																			"2.1.1 How indebted is the business? Average Historical Debt ratio",
																		target: 4,
																		achieved:
																			Number(scores["loanArrears"]) || 0,
																	},
																	{
																		id: "avgHistoricalCurrRatio",
																		question:
																			"2.1.2 What is the business's ability to serve its short-term liabilities? Average Historical Current ratio",
																		target: 3,
																		achieved:
																			Number(scores["expenseRatio"]) || 0,
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>{item.target}</TableCell>
																		<TableCell>
																			<Box
																				sx={{
																					display: "inline-block",
																					backgroundColor:
																						scoreColors[item.achieved],
																					color: "black",
																					fontWeight: "bold",
																					padding: "2px 6px",
																					borderRadius: "4px",
																				}}
																			>
																				{item.achieved}
																			</Box>
																		</TableCell>
																	</TableRow>
																))}

																{/* Affordability - Monthly Payment */}
																<TableRow>
																	<TableCell
																		sx={{
																			width: "60%",
																			backgroundColor: "#003f5c",
																			fontWeight: "bold",
																			color: "white",
																		}}
																	>
																		2.2 Affordability - Monthly Payment
																	</TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																</TableRow>
																{[
																	{
																		id: "dscrOperatingProfit12Months",
																		question:
																			"2.2.1 DSCR - Operating Profit Based - 12 months average",
																		target: 3,
																		achieved:
																			Number(scores["loanArrears"]) || 0,
																	},
																	{
																		id: "dscrDispIncome12Months",
																		question:
																			"2.2.2 DSCR - Disp. Income - 12 months average",
																		target: 4,
																		achieved:
																			Number(scores["expenseRatio"]) || 0,
																	},
																	{
																		id: "dscrOperatingProfit3Months",
																		question:
																			"2.2.3 DSCR - Operating Profit Based - 3 months average",
																		target: 2,
																		achieved:
																			Number(scores["staffPayment"]) || 0,
																	},
																	{
																		id: "dscrDispIncome3Months",
																		question:
																			"2.2.4 DSCR - Disp. Income - 3 months average",
																		target: 1,
																		achieved:
																			Number(scores["staffPayment"]) || 0,
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>{item.target}</TableCell>
																		<TableCell>
																			<Box
																				sx={{
																					display: "inline-block",
																					backgroundColor:
																						scoreColors[item.achieved],
																					color: "black",
																					fontWeight: "bold",
																					padding: "2px 6px",
																					borderRadius: "4px",
																				}}
																			>
																				{item.achieved}
																			</Box>
																		</TableCell>
																	</TableRow>
																))}
																{/* <TableRow
                                  sx={{
                                    backgroundColor: "#e3ebf0",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <TableCell>Outcome Total</TableCell>
                                  <TableCell>
                                    {[
                                      {
                                        id: "dscrOperatingProfit12Months",
                                        target: 4,
                                      },
                                      {
                                        id: "dscrDispIncome12Months",
                                        target: 4,
                                      },
                                      {
                                        id: "dscrOperatingProfit3Months",
                                        target: 2,
                                      },
                                      {
                                        id: "dscrDispIncome3Months",
                                        target: 1,
                                      },
                                    ].reduce(
                                      (acc, item) => acc + item.target,
                                      0
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      sx={{
                                        display: "inline-block",
                                        backgroundColor: "grey",
                                        color: "white",
                                        fontWeight: "bold",
                                        padding: "2px 6px",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      {getSelectedScoresTotal(scores, [
                                        {
                                          id: "dscrOperatingProfit12Months",
                                          target: 4,
                                        },
                                        {
                                          id: "dscrDispIncome12Months",
                                          target: 3,
                                        },
                                        {
                                          id: "dscrOperatingProfit3Months",
                                          target: 4,
                                        },
                                        {
                                          id: "dscrDispIncome3Months",
                                          target: 3,
                                        },
                                      ])}
                                    </Box>
                                  </TableCell>
                                </TableRow> */}

																{/* Affordability - Bullet Payment (for PO/Contract Finance only) */}
																<TableRow>
																	<TableCell
																		sx={{
																			width: "60%",
																			backgroundColor: "#003f5c",
																			fontWeight: "bold",
																			color: "white",
																		}}
																	>
																		2.3 Affordability - Bullet Payment (for
																		PO/Contract Finance only)
																	</TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																</TableRow>
																{[
																	{
																		id: "dscrOperatingProfitContractOrBullet",
																		question:
																			"2.3.1 DSCR - Operating Profit Based on Contract (Bullet)",
																		target: 4,
																		achieved:
																			Number(
																				scores[
																					"dscrOperatingProfitContractOrBullet"
																				]
																			) || 0,
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>{item.target}</TableCell>
																		<TableCell>
																			<Box
																				sx={{
																					display: "inline-block",
																					backgroundColor:
																						scoreColors[item.achieved],
																					color: "black",
																					fontWeight: "bold",
																					padding: "2px 6px",
																					borderRadius: "4px",
																				}}
																			>
																				{item.achieved}
																			</Box>
																		</TableCell>
																	</TableRow>
																))}
																{/* <TableRow
                                  sx={{
                                    backgroundColor: "#e3ebf0",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <TableCell>Outcome Total</TableCell>
                                  <TableCell>
                                    {[
                                      {
                                        id: "dscrOperatingProfitContractOrBullet",
                                        target: 3,
                                      },
                                    ].reduce(
                                      (acc, item) => acc + item.target,
                                      0
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      sx={{
                                        display: "inline-block",
                                        backgroundColor: "grey",
                                        color: "white",
                                        fontWeight: "bold",
                                        padding: "2px 6px",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      {getSelectedScoresTotal(scores, [
                                        {
                                          id: "dscrOperatingProfitContractOrBullet",
                                          target: 4,
                                        },
                                      ])}
                                    </Box>
                                  </TableCell>
                                </TableRow> */}

																{/* Affordability - Total Payment (for PO/Contract Finance only) */}
																<TableRow>
																	<TableCell
																		sx={{
																			width: "60%",
																			backgroundColor: "#003f5c",
																			fontWeight: "bold",
																			color: "white",
																		}}
																	>
																		2.4 Affordability - Total Payment (for
																		PO/Contract Finance only)
																	</TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																</TableRow>
																{[
																	{
																		id: "dscrOperatingProfitContractOrTotal",
																		question:
																			"2.4.1 DSCR - Operating Profit Based on Contract (Total)",
																		target: 4,
																		achieved:
																			Number(
																				scores[
																					"dscrOperatingProfitContractOrTotal"
																				]
																			) || 0,
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>{item.target}</TableCell>
																		<TableCell>
																			<Box
																				sx={{
																					display: "inline-block",
																					backgroundColor:
																						scoreColors[item.achieved],
																					color: "black",
																					fontWeight: "bold",
																					padding: "2px 6px",
																					borderRadius: "4px",
																				}}
																			>
																				{item.achieved}
																			</Box>
																		</TableCell>
																	</TableRow>
																))}
																{/* <TableRow
                                  sx={{
                                    backgroundColor: "#e3ebf0",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <TableCell>Outcome Total</TableCell>
                                  <TableCell>
                                    {[
                                      {
                                        id: "dscrOperatingProfitContractOrTotal",
                                        target: 3,
                                      },
                                    ].reduce(
                                      (acc, item) => acc + item.target,
                                      0
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      sx={{
                                        display: "inline-block",
                                        backgroundColor: "grey",
                                        color: "white",
                                        fontWeight: "bold",
                                        padding: "2px 6px",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      {getSelectedScoresTotal(scores, [
                                        {
                                          id: "dscrOperatingProfitContractOrTotal",
                                          target: 4,
                                        },
                                      ])}
                                    </Box>
                                  </TableCell>
                                </TableRow> */}

																{/* Risks to Revenue */}
																<TableRow>
																	<TableCell
																		sx={{
																			width: "60%",
																			backgroundColor: "#003f5c",
																			fontWeight: "bold",
																			color: "white",
																		}}
																	>
																		2.5 Risks to Revenue
																	</TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																</TableRow>
																{[
																	{
																		id: "riskContractOrPurchaseOrder",
																		question:
																			"2.5.1 Contract or Purchase Order",
																		target: 4,
																		achieved:
																			Number(
																				scores["riskContractOrPurchaseOrder"]
																			) || 0,
																	},
																	{
																		id: "riskCustomerConcentration",
																		question: "2.5.2 Customer concentration",
																		target: 4,
																		achieved:
																			Number(
																				scores["riskCustomerConcentration"]
																			) || 0,
																	},
																	{
																		id: "riskDepositFreq",
																		question: "2.5.3 Frequency of deposits",
																		target: 4,
																		achieved:
																			Number(scores["riskDepositFreq"]) || 0,
																	},
																	{
																		id: "riskRevenueVolatility",
																		question:
																			"2.5.4 Revenue Volatility - Monthly revenue std. deviation (%)",
																		target: 4,
																		achieved:
																			Number(scores["riskRevenueVolatility"]) ||
																			0,
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>{item.target}</TableCell>
																		<TableCell>
																			<Box
																				sx={{
																					display: "inline-block",
																					backgroundColor:
																						scoreColors[item.achieved],
																					color: "black",
																					fontWeight: "bold",
																					padding: "2px 6px",
																					borderRadius: "4px",
																				}}
																			>
																				{item.achieved}
																			</Box>
																		</TableCell>
																	</TableRow>
																))}
																{/* <TableRow
                                  sx={{
                                    backgroundColor: "#e3ebf0",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <TableCell>Outcome Total</TableCell>
                                  <TableCell>
                                    {[
                                      {
                                        id: "riskContractOrPurchaseOrder",
                                        target: 4,
                                      },
                                      {
                                        id: "riskCustomerConcentration",
                                        target: 4,
                                      },
                                      { id: "riskDepositFreq", target: 3 },
                                      {
                                        id: "riskRevenueVolatility",
                                        target: 3,
                                      },
                                    ].reduce(
                                      (acc, item) => acc + item.target,
                                      0
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      sx={{
                                        display: "inline-block",
                                        backgroundColor: "grey",
                                        color: "white",
                                        fontWeight: "bold",
                                        padding: "2px 6px",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      {getSelectedScoresTotal(scores, [
                                        {
                                          id: "riskContractOrPurchaseOrder",
                                          target: 4,
                                        },
                                        {
                                          id: "riskCustomerConcentration",
                                          target: 4,
                                        },
                                        { id: "riskDepositFreq", target: 3 },
                                        {
                                          id: "riskRevenueVolatility",
                                          target: 3,
                                        },
                                      ])}
                                    </Box>
                                  </TableCell>
                                </TableRow> */}
															</TableBody>
														</Table>
													</AccordionDetails>
												</Accordion>
											</TableCell>
										</TableRow>

										{/* Collateral */}
										<TableRow>
											<TableCell colSpan={3} sx={{ padding: 0 }}>
												<Accordion defaultExpanded={false}>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														sx={{ backgroundColor: "#f5f5f5" }}
													>
														<Typography sx={{ fontWeight: "bold" }}>
															3. Collateral
														</Typography>
													</AccordionSummary>
													<AccordionDetails sx={{ padding: 0 }}>
														<Table>
															<TableBody>
																{/* Cession */}
																<TableRow>
																	<TableCell
																		sx={{
																			width: "60%",
																			backgroundColor: "#003f5c",
																			fontWeight: "bold",
																			color: "white",
																		}}
																	></TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																</TableRow>
																{[
																	{
																		id: "cession",
																		question: "3.1 Cession",
																		target: 4,
																		achieved: Number(scores["cession"]) || 0,
																	},
																	{
																		id: "bizAssetAsCollateral",
																		question:
																			"3.2 Business Asset as Collateral",
																		target: 4,
																		achieved: Number(scores["cession"]) || 0,
																	},
																	{
																		id: "personalSurety",
																		question:
																			"3.3 Personal Surety - Net Worth (Total Asset minus Total Liability)",
																		target: 4,
																		achieved: Number(scores["cession"]) || 0,
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>{item.target}</TableCell>
																		<TableCell>
																			<Box
																				sx={{
																					display: "inline-block",
																					backgroundColor:
																						scoreColors[item.achieved],
																					color: "black",
																					fontWeight: "bold",
																					padding: "2px 6px",
																					borderRadius: "4px",
																				}}
																			>
																				{item.achieved}
																			</Box>
																		</TableCell>
																	</TableRow>
																))}
																{/* <TableRow
                                  sx={{
                                    backgroundColor: "#e3ebf0",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <TableCell>Outcome Total</TableCell>
                                  <TableCell>
                                    {[
                                      { id: "cession", target: 4 },
                                      { id: "bizAssetAsCollateral", target: 4 },
                                      { id: "personalSurety", target: 4 },
                                    ].reduce(
                                      (acc, item) => acc + item.target,
                                      0
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <Box
                                      sx={{
                                        display: "inline-block",
                                        backgroundColor: "grey",
                                        color: "white",
                                        fontWeight: "bold",
                                        padding: "2px 6px",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      {getSelectedScoresTotal(scores, [
                                           { id: "cession", target: 3 },
										   { id: "bizAssetAsCollateral", target: 3 },
										   { id: "personalSurety", target: 3 },
                                      ])}
                                    </Box>
                                  </TableCell>
                                </TableRow> */}
															</TableBody>
														</Table>
													</AccordionDetails>
												</Accordion>
											</TableCell>
										</TableRow>

										{/* Capital */}
										<TableRow>
											<TableCell colSpan={3} sx={{ padding: 0 }}>
												<Accordion defaultExpanded={false}>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														sx={{ backgroundColor: "#f5f5f5" }}
													>
														<Typography sx={{ fontWeight: "bold" }}>
															4. Capital
														</Typography>
													</AccordionSummary>
													<AccordionDetails sx={{ padding: 0 }}>
														<Table>
															<TableBody>
																{/* Capital */}
																<TableRow>
																	<TableCell
																		sx={{
																			width: "60%",
																			backgroundColor: "#003f5c",
																			fontWeight: "bold",
																			color: "white",
																		}}
																	></TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																</TableRow>
																{[
																	{
																		id: "personalContrInvoices",
																		question:
																			"4.1 Personal contribution to invoice(s) (% of total amount)",
																		target: 4,
																		achieved: Number(scores["cession"]) || 0,
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>{item.target}</TableCell>
																		<TableCell>
																			<Box
																				sx={{
																					display: "inline-block",
																					backgroundColor:
																						scoreColors[item.achieved],
																					color: "black",
																					fontWeight: "bold",
																					padding: "2px 6px",
																					borderRadius: "4px",
																				}}
																			>
																				{item.achieved}
																			</Box>
																		</TableCell>
																	</TableRow>
																))}
															</TableBody>
														</Table>
													</AccordionDetails>
												</Accordion>
											</TableCell>
										</TableRow>

										{/* Conditions */}
										<TableRow>
											<TableCell colSpan={3} sx={{ padding: 0 }}>
												<Accordion defaultExpanded={false}>
													<AccordionSummary
														expandIcon={<ExpandMoreIcon />}
														sx={{ backgroundColor: "#f5f5f5" }}
													>
														<Typography sx={{ fontWeight: "bold" }}>
															5. Conditions
														</Typography>
													</AccordionSummary>
													<AccordionDetails sx={{ padding: 0 }}>
														<Table>
															<TableBody>
																{/* Conditions */}
																<TableRow>
																	<TableCell
																		sx={{
																			width: "60%",
																			backgroundColor: "#003f5c",
																			fontWeight: "bold",
																			color: "white",
																		}}
																	></TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																	<TableCell
																		sx={{
																			width: "20%",
																			backgroundColor: "#003f5c",
																		}}
																	></TableCell>
																</TableRow>
																{[
																	{
																		id: "collectionRisk",
																		question: "5.1 Collection risk",
																		target: 4,
																		achieved: Number(scores["cession"]) || 0,
																	},
																].map((item) => (
																	<TableRow key={item.id}>
																		<TableCell>{item.question}</TableCell>
																		<TableCell>{item.target}</TableCell>
																		<TableCell>
																			<Box
																				sx={{
																					display: "inline-block",
																					backgroundColor:
																						scoreColors[item.achieved],
																					color: "black",
																					fontWeight: "bold",
																					padding: "2px 6px",
																					borderRadius: "4px",
																				}}
																			>
																				{item.achieved}
																			</Box>
																		</TableCell>
																	</TableRow>
																))}
															</TableBody>
														</Table>
													</AccordionDetails>
												</Accordion>
											</TableCell>
										</TableRow>

										{/* Final */}
										<TableRow>
											<TableCell colSpan={3} sx={{ padding: 0 }}>
												<Table>
													<TableBody>
														<TableRow
															sx={{
																backgroundColor: "#f5f5f5",
																fontWeight: "bold",
															}}
														>
															<TableCell>Scorecard Total</TableCell>
															<TableCell></TableCell>
															<TableCell></TableCell>
															<TableCell></TableCell>
															<TableCell>
																<Box
																	sx={{
																		display: "inline-block",
																		backgroundColor: "black",
																		color: "white",
																		fontWeight: "bold",
																		padding: "2px 6px",
																		borderRadius: "4px",
																	}}
																>
																	{Object.values(scores).reduce(
																		(acc, score) =>
																			acc + (parseInt(score) || 0),
																		0
																	)}
																</Box>
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
						</CardContent>
					</Card>
				)}

				{/*Overview*/}
				{activeTab === 4 && (
					<Box sx={{ padding: 3 }}>
						{/* Deal Overview Section */}
						<Card>
							<CardContent>
								<SectionTitle variant="h6">Deal Overview</SectionTitle>
								<Typography sx={{ fontStyle: "italic", padding: 1 }}>
									The loan request of R180k is to purchase the machinery
									necessary to produce locally her brand of papers. Request is
									for a 90-day loan duration.
								</Typography>

								<Accordion defaultExpanded={false}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<SubSectionTitle variant="h6">Ask</SubSectionTitle>
									</AccordionSummary>
									<AccordionDetails>
										<TableContainer component={Paper}>
											<Table>
												<TableBody>
													<TableRow>
														<TableCell>
															<strong>Funding Product</strong>
														</TableCell>
														<TableCell>
															<strong>Sector</strong>
														</TableCell>
														<TableCell>
															<strong>Est. Ticket Size</strong>
														</TableCell>
														<TableCell>
															<strong>Est. Duration</strong>
														</TableCell>
														<TableCell>
															<strong>PO/Contract</strong>
														</TableCell>
														<TableCell>
															<strong>Collateral</strong>
														</TableCell>
														<TableCell>
															<strong>First Time Client</strong>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															<FormControl fullWidth>
																<InputLabel>Funding Product</InputLabel>
																<Select defaultValue="ST Input Finance">
																	<MenuItem value="ST Input Finance">
																		ST Input Finance
																	</MenuItem>
																	<MenuItem value="Working Capital">
																		Working Capital
																	</MenuItem>
																</Select>
															</FormControl>
														</TableCell>
														<TableCell>
															<FormControl fullWidth>
																<InputLabel>Sector</InputLabel>
																<Select defaultValue="Other">
																	<MenuItem value="Agriculture">
																		Agriculture
																	</MenuItem>
																	<MenuItem value="Manufacturing">
																		Manufacturing
																	</MenuItem>
																	<MenuItem value="Other">Other</MenuItem>
																</Select>
															</FormControl>
														</TableCell>
														<TableCell>R90,000.00</TableCell>
														<TableCell>90 Days</TableCell>
														<TableCell>No</TableCell>
														<TableCell>No</TableCell>
														<TableCell>
															<FormControl fullWidth>
																<InputLabel>First Time Client</InputLabel>
																<Select defaultValue="Yes">
																	<MenuItem value="Yes">Yes</MenuItem>
																	<MenuItem value="No">No</MenuItem>
																</Select>
															</FormControl>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</AccordionDetails>
								</Accordion>

								<Accordion defaultExpanded={false}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<SubSectionTitle variant="h6">Offer</SubSectionTitle>
									</AccordionSummary>
									<AccordionDetails>
										<TableContainer component={Paper}>
											<Table>
												<TableBody>
													<TableRow>
														<TableCell>
															<strong>Ticket Size</strong>
														</TableCell>
														<TableCell>
															<strong>Duration</strong>
														</TableCell>
														<TableCell>
															<strong>Initiation Fee</strong>
														</TableCell>
														<TableCell>
															<strong>Interest Charge</strong>
														</TableCell>
														<TableCell>
															<strong>Loan End Date</strong>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>R50,000.00</TableCell>
														<TableCell>90 Days</TableCell>
														<TableCell>R0</TableCell>
														<TableCell>R10,890</TableCell>
														<TableCell>26/5/2025</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</AccordionDetails>
								</Accordion>

								{/* Credit Assessment Section */}
								<Box mt={3} mb={3}>
									<SectionTitle variant="h6">Credit Assessment</SectionTitle>
									<Card>
										<CardContent>
											{/* Flag Rules Section */}
											<Accordion defaultExpanded={false} sx={{ marginTop: 2 }}>
												<AccordionSummary expandIcon={<ExpandMoreIcon />}>
													<SubSectionTitle variant="h6">
														Flag Rules
													</SubSectionTitle>
												</AccordionSummary>
												<AccordionDetails>
													{/* Pumpkin Credit Score Block */}
													<Box
														display="flex"
														justifyContent="space-between"
														mb={3}
													>
														<Card
															sx={{
																width: "48%",
																padding: 2,
																backgroundColor: "#e3ebf0",
															}}
														>
															<Typography variant="h6" textAlign="center">
																Pumpkin Credit Score
															</Typography>
															<Typography
																variant="h4"
																textAlign="center"
																fontWeight="bold"
															>
																79
															</Typography>
														</Card>
														<Card
															sx={{
																width: "48%",
																padding: 2,
																backgroundColor: "#e3ebf0",
															}}
														>
															<Typography variant="h6" textAlign="center">
																Discount
															</Typography>
															<Typography
																variant="h4"
																textAlign="center"
																fontWeight="bold"
															>
																4%
															</Typography>
														</Card>
													</Box>
													<TableContainer component={Paper}>
														<Table>
															<TableHead>
																<TableRow sx={{ backgroundColor: "#c1d5e0" }}>
																	<TableCell align="center">
																		<strong>Compliance</strong>
																	</TableCell>
																	<TableCell align="center">
																		<strong>12 Month Revenue</strong>
																	</TableCell>
																	<TableCell align="center">
																		<strong>Honesty</strong>
																	</TableCell>
																	<TableCell align="center">
																		<strong>Responsibility</strong>
																	</TableCell>
																	<TableCell align="center">
																		<strong>Reputation</strong>
																	</TableCell>
																</TableRow>
															</TableHead>
															<TableBody>
																<TableRow>
																	{[
																		"green",
																		"amber",
																		"red",
																		"amber",
																		"green",
																	].map((status, index) => (
																		<TableCell key={index}>
																			<Box
																				sx={{
																					backgroundColor:
																						status === "green"
																							? "#A7E0A7"
																							: status === "amber"
																								? "#FBC02D"
																								: "#E57373",
																					width: "50px",
																					height: "30px",
																					borderRadius: "4px",
																					margin: "auto",
																				}}
																			/>
																		</TableCell>
																	))}
																</TableRow>
															</TableBody>
														</Table>
													</TableContainer>
												</AccordionDetails>
											</Accordion>
										</CardContent>
									</Card>
								</Box>

								<SectionTitle variant="h6">Key Financial Metrics</SectionTitle>
								<Accordion defaultExpanded={false}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<SubSectionTitle variant="h6">Metrics</SubSectionTitle>
									</AccordionSummary>
									<AccordionDetails>
										<TableContainer component={Paper}>
											<Table>
												<TableBody>
													<TableRow>
														<TableCell>
															<strong>Total Revenue (last 3M)</strong>
														</TableCell>
														<TableCell>
															<strong>Gross Op. Margin (Avg. last 3M)</strong>
														</TableCell>
														<TableCell>
															<strong>Avg. # of rev. transactions</strong>
														</TableCell>
														<TableCell>
															<strong>% personal expenses (last 3M)</strong>
														</TableCell>
														<TableCell>
															<strong>% unrecon. expenses (last 3M)</strong>
														</TableCell>
														<TableCell>
															<strong>Op. Profit DSCR (last 3M)</strong>
														</TableCell>
														<TableCell>
															<strong>Disp. Income DSCR (last 3M)</strong>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>R460,567</TableCell>
														<TableCell>56%</TableCell>
														<TableCell>2.3</TableCell>
														<TableCell>9.8%</TableCell>
														<TableCell>10.1%</TableCell>
														<TableCell>13.0</TableCell>
														<TableCell>5.6</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</AccordionDetails>
								</Accordion>

								<Box mt={3} />

								<SectionTitle variant="h6">Key Revenue Drivers</SectionTitle>
								<Accordion defaultExpanded={false}>
									<AccordionSummary expandIcon={<ExpandMoreIcon />}>
										<SubSectionTitle variant="h6">Drivers</SubSectionTitle>
									</AccordionSummary>
									<AccordionDetails>
										<TableContainer component={Paper}>
											<Table>
												<TableBody>
													<TableRow>
														<TableCell>
															<strong>Top 3 Revenue Drivers</strong>
														</TableCell>
														<TableCell>
															<strong>Reported GOM by Driver</strong>
														</TableCell>
														<TableCell>
															<strong># of Unique Clients (last 3M)</strong>
														</TableCell>
														<TableCell>
															<strong># of Deposits (last 3M)</strong>
														</TableCell>
														<TableCell>
															<strong>Top 3 - Deposits (last 3M)</strong>
														</TableCell>
													</TableRow>
													<TableRow>
														<TableCell>
															<Typography variant="body2">
																<strong>1.</strong> Local Sales of Paper (45%)
															</Typography>
															<Typography variant="body2">
																<strong>2.</strong> International Sales of Paper
																(30%)
															</Typography>
															<Typography variant="body2">
																<strong>3.</strong> Website development &
																webhosting (25%)
															</Typography>
														</TableCell>
														<TableCell>N.A</TableCell>
														<TableCell>Est. 4+</TableCell>
														<TableCell>31.0</TableCell>
														<TableCell>
															<Typography variant="body2">
																<strong>1.</strong> FNB App Payment From Ll -
																R15,000.00
															</Typography>
															<Typography variant="body2">
																<strong>2.</strong> FNB App Payment From Ll2 -
																R15,000.00
															</Typography>
															<Typography variant="body2">
																<strong>3.</strong> FNB App Payment From Nb
																Attorneys - R322,000.00
															</Typography>
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</AccordionDetails>
								</Accordion>

								<Box mt={3}>
									<Card>
										<CardContent>
											<Typography
												variant="h6"
												sx={{
													backgroundColor: "#003f5c",
													color: "white",
													padding: "8px",
													textAlign: "center",
													fontWeight: "bold",
													borderRadius: "4px",
												}}
											>
												Recommendation & Notes
											</Typography>

											{[
												{
													label: "Sales",
													field: "salesDecision",
													rationaleField: "salesRationale",
												},
												{
													label: "Credit",
													field: "creditDecision",
													rationaleField: "creditRationale",
												},
												{
													label: "Credit Committee Chair",
													field: "creditCommitteeDecision",
													rationaleField: "creditCommitteeRationale",
												},
											].map((item, index) => {
												const selectedColor =
													recommendationOptions.find(
														(opt) =>
															opt.value ===
															decisions[item.field as keyof DecisionState]
													)?.color || "white";

												return (
													<Box key={index} mt={2}>
														<Box display="flex" alignItems="center">
															<Typography variant="h6" sx={{ flex: 1 }}>
																{item.label}
															</Typography>
															<FormControl
																sx={{
																	width: 200,
																	backgroundColor: selectedColor,
																	borderRadius: 1,
																}}
															>
																<InputLabel>Decision</InputLabel>
																<Select
																	value={
																		decisions[item.field as keyof DecisionState]
																	}
																	onChange={(e) =>
																		handleChange(
																			item.field as keyof DecisionState,
																			e.target.value
																		)
																	}
																>
																	{recommendationOptions.map((option) => (
																		<MenuItem
																			key={option.value}
																			value={option.value}
																		>
																			{option.value}
																		</MenuItem>
																	))}
																</Select>
															</FormControl>
														</Box>

														{/* Rationale Text Area */}
														<Box mt={1}>
															<TextField
																fullWidth
																variant="outlined"
																multiline
																rows={6}
																placeholder={`Enter rationale for ${item.label} decision...`}
																value={
																	decisions[
																		item.rationaleField as keyof DecisionState
																	]
																}
																onChange={(e) =>
																	handleChange(
																		item.rationaleField as keyof DecisionState,
																		e.target.value
																	)
																}
															/>
														</Box>
													</Box>
												);
											})}
										</CardContent>
									</Card>
								</Box>
							</CardContent>
						</Card>
					</Box>
				)}
			</Box>
		</Modal>
	);
};

export default CreditScoreCardModal;
