import React, { useState } from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import CustomTable from "../Tables/CustomTable";
import CustomTabs from "../Elements/CustomTabs";
import { getManagementAccount } from "../../services/retool-service";
import { useQuery, useQueryClient } from "react-query";
import LoadingIconButton from "../Elements/LoadingIconButton";

const ManagementAccountModal = ({ open, onClose, userId }) => {
	const theme = useTheme();
	const queryClient = useQueryClient();
	const [tabIndex, setTabIndex] = useState(0);
	const [selectedRows, setSelectedRows] = useState([]);

	const { data, isLoading, isRefetching, refetch } = useQuery(
		["management-accounts", userId],
		() => getManagementAccount(userId),
		{
			select: (response) =>
				response?.data?.data?.transactionCategorySummaries || [],
		}
	);
	const extractColumns = (cat, index) => {
		if (!data || !Array.isArray(data)) return [];

		const months = new Set();

		data.forEach((category) => {
			category?.yearlySummaries?.forEach((yearSummary) => {
				yearSummary?.monthlySummaries?.forEach((monthSummary) => {
					months.add(`${yearSummary.year}(${monthSummary.month.slice(0, 3)})`);
				});
			});

			category?.subCategorySummaries?.forEach((subCategory) => {
				subCategory?.yearlySummaries?.forEach((yearSummary) => {
					yearSummary?.monthlySummaries?.forEach((monthSummary) => {
						months.add(
							`${yearSummary.year}(${monthSummary.month.slice(0, 3)})`
						);
					});
				});
			});
		});

		const sortedMonths = Array.from(months).sort((a, b) => {
			const [yearA, monthA] = a.split("-");
			const [yearB, monthB] = b.split("-");

			return (
				Number(yearA) - Number(yearB) ||
				getMonthIndex(monthA) - getMonthIndex(monthB)
			);
		});

		return [
			{ title: index == 0 ? "MA (Rands)" : "", key: "name", sortable: false },
			...sortedMonths.map((month) => ({
				title: index == 0 ? month : "",
				key: month,
				sortable: false,
				withSignFormating: true,
				isDecimal: cat == "Operating Margin" ? false : true,
			})),
		];
	};

	const getMonthIndex = (monthAbbrev) => {
		const monthsMap = {
			Jan: 1,
			Feb: 2,
			Mar: 3,
			Apr: 4,
			May: 5,
			Jun: 6,
			Jul: 7,
			Aug: 8,
			Sep: 9,
			Oct: 10,
			Nov: 11,
			Dec: 12,
		};
		return monthsMap[monthAbbrev] || 0;
	};

	const extractRows = (categoryName) => {
		if (!data || !Array.isArray(data)) return [];

		let rows = [];

		data.forEach((category) => {
			if (category.category !== categoryName) return;

			const categoryRow = {
				name:
					categoryName == "Operating Margin"
						? category.category + " (%)"
						: category.category,
			};

			category?.yearlySummaries?.forEach((yearSummary) => {
				yearSummary?.monthlySummaries?.forEach((monthSummary) => {
					categoryRow[
						`${yearSummary.year}(${monthSummary.month.slice(0, 3)})`
					] = monthSummary.monthlyTotalInDecimal;
				});
			});

			rows.push(categoryRow);

			category?.subCategorySummaries?.forEach((subCategory) => {
				const subCategoryRow = { name: `${subCategory.subCategory}` };

				subCategory?.yearlySummaries?.forEach((yearSummary) => {
					yearSummary?.monthlySummaries?.forEach((monthSummary) => {
						subCategoryRow[
							`${yearSummary.year}(${monthSummary.month.slice(0, 3)})`
						] = monthSummary.monthlyTotalInDecimal;
					});
				});

				rows.push(subCategoryRow);
			});
		});

		return rows.length ? rows : [{ name: "No Data Available" }];
	};

	const firstTabCategories = [
		"Revenue",
		"Direct Expenses",
		"Gross Profit Before Fixed Costs",
		"Operating Margin",
		"Overheads Plus Unreconciled Expenses",
		"Net Operating Profit Before Tax",
	];

	const secondTabCategories = [
		"Starting Cash",
		"Operational Cashflow",
		"Total Overheads plus Unreconciled Expenses",
		"Revenue",
		"Direct Expenses",
		"Investment Cashflow",
		"Financing Cashflow",
		"Equity & Loans",
		"Net Cashflow",
	];

	const tabs = [
		{ label: "Profit & Loss", value: 0 },
		{ label: "Cashflows", value: 1 },
	];

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					p: 3,
					bgcolor: theme.palette.background.paper,
					overflow: "auto",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 3,
					}}
				>
					<Typography
						sx={{ fontSize: 24, fontWeight: 500 }}
						color={theme.palette.text.primary}
					>
						Management Account
					</Typography>
					<IconButton onClick={onClose}>
						<Close sx={{ fill: theme.palette.text.primary }} />
					</IconButton>
				</Box>

				<CustomTabs
					tabs={tabs}
					selectedTab={tabIndex}
					onTabChange={(_, newValue) => setTabIndex(newValue)}
				/>

				{tabIndex === 0 && (
					<>
						<Box
							textAlign="right"
							display="flex"
							justifyContent="flex-end"
							mb={2}
						>
							<LoadingIconButton
								onClick={() => {
									refetch();
									queryClient.invalidateQueries("uploadedBankStatements");
									queryClient.invalidateQueries("allUploadedBankStatements");
								}}
								isLoading={isLoading || isRefetching}
							/>
						</Box>
						<Box display="flex" flexDirection="column" gap={2}>
							{firstTabCategories.map((category, index) => (
								<CustomTable
									key={category}
									title={category}
									isLoading={isLoading || isRefetching}
									columns={extractColumns(category, index)}
									data={extractRows(category)}
									selectable={false}
									highlightFirstRow={true}
									showPagination={false}
									count={100}
								/>
							))}
						</Box>
					</>
				)}

				{tabIndex === 1 && (
					<Box display="flex" flexDirection="column" gap={2}>
						{secondTabCategories.map((category, index) => (
							<CustomTable
								key={category}
								title={category}
								isLoading={isLoading || isRefetching}
								columns={extractColumns(category, index)}
								data={extractRows(category)}
								selectable={false}
								highlightFirstRow={true}
								showPagination={false}
								count={100}
							/>
						))}
					</Box>
				)}
			</Box>
		</Modal>
	);
};

export default ManagementAccountModal;
