import React from "react";
import { Modal, Box, Typography, useTheme } from "@mui/material";

interface FlagModalProps {
	title: string;
	body: string;
	isOpen: boolean;
	onClose: () => void;
}

const ResponseModal: React.FC<FlagModalProps> = ({
	title,
	body,
	isOpen,
	onClose,
}) => {
	const theme = useTheme();

	const handleSave = () => {};

	return (
		<Modal open={isOpen} onClose={onClose}>
			<Box
				sx={{
					width: "312px",
					height: "auto",
					backgroundColor:
						theme.palette.mode === "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					position: "relative",
					top: "10%",
				}}
			>
				<Typography
					mb={3}
					color={theme.palette.text.primary}
					fontSize="24px"
					lineHeight="32px"
					fontFamily="Roboto"
					fontWeight="500"
					gutterBottom
				>
					{title}
				</Typography>

				<Typography
					mb={3}
					color={theme.palette.text.secondary}
					fontSize="14px"
					lineHeight="20px"
					letterSpacing="0.25px"
					fontFamily="Roboto"
					fontWeight="400"
					gutterBottom
				>
					{body}
				</Typography>

				<Box
					p={2}
					sx={{
						display: "flex",
						gap: 2,
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<Typography
						sx={{
							fontFamily: "Roboto",
							fontWeight: 500,
							fontSize: "14px",
							lineHeight: "20px",
							letterSpacing: "0.1px",
							color: theme.palette.primary.main,
							cursor: "pointer",
						}}
						onClick={onClose}
					>
						Cancel
					</Typography>
					<Typography
						onClick={handleSave}
						sx={{
							fontFamily: "Roboto",
							fontWeight: 500,
							fontSize: "14px",
							lineHeight: "20px",
							letterSpacing: "0.1px",
							color: theme.palette.primary.main,
							cursor: "pointer",
						}}
					>
						Save
					</Typography>
				</Box>
			</Box>
		</Modal>
	);
};

export default ResponseModal;
