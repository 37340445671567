import React, { useState } from "react";
import {
	CreditScore,
	Add,
	Person,
	AccountBalance,
	Description,
	BarChart,
	Delete,
	FormatAlignLeftOutlined,
	MovingOutlined,
} from "@mui/icons-material";

import FloatingActionMenu, { Action } from "../Elements/Fab";
import LinkBankAccountModal from "../Modals/LinkBankAccountModal";
import FinancialDataModal from "../Modals/FinancialDataModal";

import moment from "moment";
import useUserBankData from "../../../common/helpers/hooks/useUserBankData";
import { useClientDealChecklists } from "../../../common/helpers/hooks/useClientService";
import ClientDealChecklistModal from "../Modals/ClientDealChecklistModal";
import CreditReportModal from "../Modals/CreditReportModal";
import UpdateProfileModal from "../Modals/UpdateProfileModal";
import FundingNeedModal from "../Modals/FundingNeedModal";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { deleteUsers } from "../../../common/services/retool-service";
import ConfirmationModal from "../Modals/ConfirmationModal";
import { LinearProgress } from "@mui/material";
import ClientBackgroundJobModal from "../Modals/ClientBackgroundJobModal";
import BankAccountModal from "../Modals/BankAccountModal";
import LabelledFinancialDataModal from "../Modals/LabelletedFinancialDataModal";

interface FabProps {
	data?: any;
	selectedRows?: any[];
	refetch: () => void;
}

const ClientsFloatingActionMenu: React.FC<FabProps> = ({
	data,
	selectedRows = [],
	refetch,
}) => {
	const userId = data?.userId;

	const {
		uploadedBankStatementsData,
		transactionHistoriesData,
		isLoadingHistories,
		isLoadingStatements,
		refetchHistories,
		refetchStatements,
	} = useUserBankData(userId);

	const {
		data: clientDealchecklists,
		isLoading: isClientDealChecklistsFetching,
		refetch: refetchDealchecklists,
	} = useClientDealChecklists(userId);

	const [openManageBankAccountModal, setOpenManageBankAccountModal] =
		useState(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [openOnoardingProgressModal, setOpenOnboardingProgressModal] =
		useState(false);
	const [confirmationMessage, setConfirmationMessage] =
		useState<React.ReactNode | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const queryClient = useQueryClient();

	const [openCreditReportCheckModal, setOpenCreditReportCheckModal] =
		useState(false);
	const [openAddNewDeal, setOpenAddNewDeal] = useState(false);
	const [openRequestLinkBankModal, setOpenRequestLinkBankModal] =
		useState(false);
	const [openUpdateProfileModal, setOpenUpdateProfileModal] = useState(false);
	const [openViewDocumentsModal, setOpenViewDocumentsModal] = useState(false);
	const [openFinancialAnalysisModal, setOpenFinancialAnalysisModal] =
		useState(false);
	const [openLabelledTransactionsModal, setOpenLabelledTransactionsModal] =
		useState(false);

	const handleDelete = () => {
		setOpenConfirmationModal(true);

		const emails = selectedRows.map((row) =>
			row?.email ? row?.email : "No email, userId: " + row?.userId
		);

		setConfirmationMessage(
			<>
				<p>
					Are you sure you want to delete these clients? This action cannot be
					undone. Deleting these clients will also remove associated:
				</p>
				<ul>
					<li>User Profile</li>
					<li>Business Profile</li>
					<li>Deals</li>
					<li>Offers</li>
					<li>Contracts</li>
					<li>Lending Documents</li>
					<li>Bank Statements</li>
				</ul>
				<p>Clients to be deleted:</p>
				<ul>
					{emails.map((email, index) => (
						<li key={index}>{email}</li>
					))}
				</ul>
			</>
		);
	};

	const handleDeleteClients = async () => {
		try {
			setIsLoading(true);
			const payload = {
				userIds: selectedRows.map((row) => row.userId),
			};
			const response = await deleteUsers(payload);
			if (response.success) {
				toast.success(response.message);
				refetch();
			} else toast.error(response.message);
			setIsLoading(false);
			setOpenConfirmationModal(false);
		} catch (error) {
			toast.error("Unable to delete deals.");
			setIsLoading(false);
		}
	};

	const actions: Action[] = [
		{
			label: "Onboarding Progress",
			icon: <MovingOutlined />,
			onClick: () => {
				setOpenOnboardingProgressModal(true);
			},
		},
		{
			label: "Add New Deal",
			icon: <Add />,
			onClick: () => {
				setOpenAddNewDeal(true);
			},
		},
		{
			label: "Run Credit Check",
			icon: <CreditScore />,
			onClick: () => {
				setOpenCreditReportCheckModal(true);
			},
		},
		// {
		// 	label: "Bank Account Linkage",
		// 	icon: <AccountBalance />,
		// 	onClick: () => {
		// 		setOpenRequestLinkBankModal(true);
		// 	},
		// },
		// {
		// 	label: "View Documents",
		// 	icon: <Description />,
		// 	onClick: () => {
		// 		refetchDealchecklists();
		// 		setOpenViewDocumentsModal(true);
		// 	},
		// },
		// {
		// 	label: "Financial Analysis",
		// 	icon: <BarChart />,
		// 	onClick: () => {
		// 		refetchHistories();
		// 		refetchStatements();
		// 		setOpenFinancialAnalysisModal(true);
		// 	},
		// },
		{
			label: "Update Profile",
			icon: <Person />,
			onClick: () => {
				setOpenUpdateProfileModal(true);
			},
		},
		{
			label: "Delete Client(s)",
			icon: <Delete />,
			onClick: () => {
				handleDelete();
			},
		},
		{
			label: "Manage Bank Accounts",
			icon: <AccountBalance />,
			onClick: () => {
				setOpenManageBankAccountModal(true);
			},
		},
		{
			label: "Labelled Transactions",
			icon: <BarChart />,
			onClick: () => {
				setOpenLabelledTransactionsModal(true);
			},
		},
	];

	return (
		<>
			<FundingNeedModal
				open={openAddNewDeal}
				onClose={() => setOpenAddNewDeal(false)}
				selectedApplication={null}
				client={data}
			/>
			<UpdateProfileModal
				open={openUpdateProfileModal}
				onClose={() => setOpenUpdateProfileModal(false)}
				client={data}
			/>
			<CreditReportModal
				open={openCreditReportCheckModal}
				onClose={() => setOpenCreditReportCheckModal(false)}
				client={data}
			/>
			<LinkBankAccountModal
				open={openRequestLinkBankModal}
				onClose={() => setOpenRequestLinkBankModal(false)}
				clientEmail={data?.email}
			/>
			<FinancialDataModal
				open={openFinancialAnalysisModal}
				onClose={() => setOpenFinancialAnalysisModal(false)}
				uploadedStatements={uploadedBankStatementsData || []}
				transactionHistories={transactionHistoriesData || []}
				type="OCR"
				isFetchingTransactionHistories={isLoadingHistories}
				isFetchingUploadedStatements={isLoadingStatements}
			/>
			<ClientDealChecklistModal
				open={openViewDocumentsModal}
				onClose={() => setOpenViewDocumentsModal(false)}
				data={clientDealchecklists?.mainData || []}
				client={data}
				isLoading={isClientDealChecklistsFetching}
			/>
			<FloatingActionMenu actions={actions} />
			<ConfirmationModal
				open={openConfirmationModal}
				onClose={() => setOpenConfirmationModal(false)}
				handleConfirmAction={handleDeleteClients}
				message={confirmationMessage}
				isLoading={isLoading}
			/>
			<ClientBackgroundJobModal
				open={openOnoardingProgressModal}
				onClose={() => setOpenOnboardingProgressModal(false)}
				userId={data?.userId}
			/>
			<BankAccountModal
				open={openManageBankAccountModal}
				onClose={() => setOpenManageBankAccountModal(false)}
				userId={data?.userId}
			/>
			<LabelledFinancialDataModal
				open={openLabelledTransactionsModal}
				onClose={() => setOpenLabelledTransactionsModal(false)}
				userId={userId}
				isManagementAccountGenerated={data?.isManagementAccountGenerated}
			/>
		</>
	);
};

export default ClientsFloatingActionMenu;
