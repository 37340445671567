import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Modal,
  Grid,
  useTheme,
  TableCell,
  Tooltip,
  Divider,
  Card,
  CardContent,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PeopleIcon from "@mui/icons-material/People";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { blue, green, grey, orange, red, amber } from "@mui/material/colors";
import AffordabilityAnalysisModal from "./AffordabilityAnalysisModal";
import CreditScoreCardModal from "./CreditScoreCardModal";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ScoreIcon from "@mui/icons-material/Score";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import RateReviewIcon from "@mui/icons-material/RateReview";
import SyncIcon from "@mui/icons-material/Sync";
import WarningIcon from "@mui/icons-material/Warning";
import BarChartIcon from "@mui/icons-material/BarChart";
import GavelIcon from "@mui/icons-material/Gavel";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaidIcon from "@mui/icons-material/Paid";
import FinancialScreenModal from "./FinancialScreenModal";
import LinkedDirectorsModal from "./LinkedDirectorsModal";
import CompanyDetailsModal from "./CompanyDetailsModal";
import CompanyDirectorsModal from "./CompanyDirectorsModal";
import EnterpreneurDetailsModal from "./EnterpreneurDetailsModal";
import { useQuery } from "react-query";
import {
  getCommercialCreditReport,
  getConsumerCreditReport,
  triggerLoanDiskSync,
} from "../../../common/services/retool-service";
import useUserBankData from "../../../common/helpers/hooks/useUserBankData";
import FinancialDataModal from "./FinancialDataModal";
import { useFetchClientAssessment } from "../../../common/helpers/hooks/useClientService";
import moment from "moment";
import DefaultsModal from "./DefaultsModal";
import toast from "react-hot-toast";

interface DealProfileModalProps {
  open: boolean;
  onClose: () => void;
  selectedApplication: any;
}

type BackgroundCheckItem = {
  label: string;
  value: string;
  icon: JSX.Element;
  status: "red" | "amber" | "green";
  handleClick: () => void;
};

const DealProfileModal: React.FC<DealProfileModalProps> = ({
  open,
  onClose,
  selectedApplication,
}) => {
  const theme = useTheme();
  const [openAffordabilityAnalysisModal, setOpenAffordabilityAnalysisModal] =
    useState(false);
  const [openCreditScoreCardModal, setOpenCreditScoreCardModal] =
    useState(false);
  const [isSyncLoading, setIsSyncLoading] = useState(false);
  const [openFinancialDataModal, setOpenFinancialDataModal] = useState(false);
  const [openUserProfileModal, setOpenUserProfileModal] = useState(false);
  const [openBusinessProfileModal, setOpenBusinessProfileModal] =
    useState(false);
  const [openLinkedCompaniesModal, setOpenLinkedCompaniesModal] =
    useState(false);
  const [openDefaultsModal, setOpenDefaultsModal] = useState(false);
  const [openCommercialDefaultsModal, setOpenCommercialDefaultsModal] =
    useState(false);
  const [openCompanyDirectorsModal, setOpenCompanyDirectorsModal] =
    useState(false);

  const {
    uploadedBankStatementsData,
    transactionHistoriesData,
    isLoadingHistories,
    isLoadingStatements,
    refetchHistories,
    refetchStatements,
  } = useUserBankData(selectedApplication?.userId);

  transactionHistoriesData.forEach((x: any) => {
    if (x?.date) {
      x.date = moment(x.date, "YYYYMMDDHHmmss").isValid()
        ? moment(x.date, "YYYYMMDDHHmmss").format("YYYY-MM-DD")
        : null;
    }
  });
  const { data: userAssessment, isLoading: isFetchingAssessment } =
    useFetchClientAssessment(selectedApplication?.userId);
  const data = userAssessment?.data?.data || {};

  const consumerCreditScore = data?.consumerScore;

  const commercialCreditScore = data?.commericialScore;
  const RAG_COLORS: Record<string, string> = {
    red: "#E57373",
    amber: "#FBC02D",
    green: "#A7E0A7",
  };

  const traxLenght = Number(transactionHistoriesData?.length);
  const companyDirectorsLen = data?.noOfCompanyDirectors;
  const noOfConsumerDirectors = data?.directorCount;
  const noOfDefaults = data?.noOfConsumerDefaults;
  const noOfBusinessDefaults = data?.noOfCommercialDefaults;
  const noOfRedFlags = data?.noOfRedFlags;
  const requiredDirectCount = data?.requiredDirectorCount;
  const commercialStatus = data?.commercialStatus;

  const backgroundCheckData: BackgroundCheckItem[] = [
    {
      label: "Linked Companies",
      value: noOfConsumerDirectors || 0,
      icon: <BusinessIcon />,
      status: noOfConsumerDirectors < requiredDirectCount ? "red" : "green",
      handleClick: () => setOpenLinkedCompaniesModal(true),
    },
    {
      label: "Personal Defaults",
      value: noOfDefaults,
      icon: <WarningIcon />,
      status: noOfDefaults > 0 ? "red" : "green",
      handleClick: () => setOpenDefaultsModal(true),
    },
    {
      label: "Business Defaults",
      value: noOfBusinessDefaults,
      icon: <WarningIcon />,
      status: noOfDefaults > 0 ? "red" : "green",
      handleClick: () => setOpenCommercialDefaultsModal(true),
    },
    {
      label: "Consumer Score",
      value: consumerCreditScore || "N/A",
      icon: <BarChartIcon />,
      status:
        isNaN(Number(consumerCreditScore)) ||
        Number(consumerCreditScore) < data?.requiredConsumerScore
          ? "red"
          : "green",
      handleClick: () => {},
    },
    {
      label: "CIPC Status",
      value: commercialStatus || "N/A",
      icon: <GavelIcon />,
      status: commercialStatus == "InBusiness" ? "green" : "red",
      handleClick: () => {},
    },
    {
      label: "Company Directors",
      value: noOfConsumerDirectors || 0,
      icon: <PeopleIcon />,
      status: companyDirectorsLen > 0 ? "green" : "red",
      handleClick: () => setOpenCompanyDirectorsModal(true),
    },
    {
      label: "Commercial Score",
      value: commercialCreditScore || "N/A",
      icon: <BarChartIcon />,
      status:
        isNaN(Number(commercialCreditScore)) ||
        Number(commercialCreditScore) < data?.requiredCommercialScore
          ? "red"
          : "green",
      handleClick: () => {},
    },
    {
      label: "Transactions",
      value: traxLenght || 0,
      icon: <PaidIcon />,
      status: traxLenght > 0 ? "green" : "red",
      handleClick: () => setOpenFinancialDataModal(true),
    },
    {
      label: "Red Flags",
      value: "10",
      icon: <WarningIcon />,
      status: noOfRedFlags > 0 ? "red" : "green",
      handleClick: () => {},
    },
  ];

  const runLoanDiskSync = async () => {
    setIsSyncLoading(true);
    const toastId = toast.loading("Syncing with LMS...");

    try {
      const response = await triggerLoanDiskSync(selectedApplication.id);
      if (response.data.success) {
        toast.success(response.data.message, { id: toastId });
      } else {
        toast.error(response.data.message, { id: toastId });
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "An error occurred.", {
        id: toastId,
      });
    } finally {
      toast.dismiss(toastId);
      toast.success("Sync completed successfully!");
      setIsSyncLoading(false);
    }
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            backgroundColor:
              theme.palette.mode == "dark"
                ? theme.palette.background.default
                : theme.palette.background.paper,
            padding: "24px",
            borderRadius: "12px",
            margin: "auto",
            position: "relative",
            top: "0%",
            overflowY: "auto",
          }}
        >
          <Box
            mb={2}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <Typography variant="h6">Deal Profile</Typography> */}
            <IconButton
              edge="end"
              color="inherit"
              onClick={onClose}
              sx={{ ml: "auto" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <div style={{ padding: "20px", maxWidth: "900px", margin: "auto" }}>
            <Card sx={{ marginBottom: "20px", position: "relative" }}>
              <CardContent>
                <Box mt={3}>
                  <Typography
                    variant="h6"
                    sx={{
                      backgroundColor: "#003f5c",
                      color: "white",
                      padding: "8px",
                      textAlign: "center",
                      fontWeight: "bold",
                      borderRadius: "4px",
                    }}
                  >
                    Loan Details
                  </Typography>

                  {/* First Row */}
                  <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                    <Grid item xs={6}>
                      <Card
                        sx={{ backgroundColor: "#e3ebf0", borderRadius: "8px" }}
                      >
                        <CardContent>
                          <Typography
                            color="black"
                            display="flex"
                            alignItems="center"
                            fontWeight="bold"
                          >
                            <AttachMoneyIcon
                              sx={{ marginRight: 1, color: "#003f5c" }}
                            />{" "}
                            ASK
                          </Typography>
                          <Typography variant="h6" fontWeight="bold">
                            R
                            {selectedApplication?.fundingAmount?.toLocaleString()}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card
                        sx={{ backgroundColor: "#e3ebf0", borderRadius: "8px" }}
                      >
                        <CardContent>
                          <Typography
                            color="black"
                            display="flex"
                            alignItems="center"
                            fontWeight="bold"
                          >
                            <AccessTimeIcon
                              sx={{ marginRight: 1, color: "#003f5c" }}
                            />{" "}
                            DURATION
                          </Typography>
                          <Typography variant="h6" fontWeight="bold">
                            {selectedApplication?.duration} Days
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>

                  {/* Second Row */}
                  <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                    <Grid item xs={6}>
                      <Card
                        onClick={() => setOpenUserProfileModal(true)}
                        sx={{
                          cursor: "pointer",
                          backgroundColor: "#e3ebf0",
                          borderRadius: "8px",
                        }}
                      >
                        <CardContent>
                          <Typography
                            color="black"
                            display="flex"
                            alignItems="center"
                            fontWeight="bold"
                          >
                            <PersonIcon
                              sx={{ marginRight: 1, color: "#003f5c" }}
                            />{" "}
                            NAME
                          </Typography>
                          <Typography variant="h6" fontWeight="bold">
                            {selectedApplication?.firstname ||
                            selectedApplication?.lastname
                              ? `${selectedApplication?.firstname || ""} ${selectedApplication?.lastname || ""}`
                              : "N/A"}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      <Card
                        onClick={() => setOpenBusinessProfileModal(true)}
                        sx={{
                          cursor: "pointer",
                          backgroundColor: "#e3ebf0",
                          borderRadius: "8px",
                        }}
                      >
                        <CardContent>
                          <Typography
                            color="black"
                            display="flex"
                            alignItems="center"
                            fontWeight="bold"
                          >
                            <BusinessIcon
                              sx={{ marginRight: 1, color: "#003f5c" }}
                            />{" "}
                            BUSINESS
                          </Typography>
                          <Typography variant="h6" fontWeight="bold">
                            {selectedApplication?.companyName || "N/A"}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                  {/* Centered Review Deal Button */}
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    mt={3}
                    gap={2}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        color: "black",
                        "&:hover": { backgroundColor: "#F0F4F8" },
                        border: "2px solid black",
                        borderRadius: "8px",
                        width: "auto",
                        alignSelf: "center",
                        paddingX: 3,
                      }}
                      startIcon={<RateReviewIcon />}
                      onClick={() => setOpenCreditScoreCardModal(true)}
                    >
                      Review Deal
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        color: "black",
                        "&:hover": { backgroundColor: "#F0F4F8" },
                        border: "2px solid black",
                        borderRadius: "8px",
                        width: "auto",
                        alignSelf: "center",
                        paddingX: 3,
                      }}
                      startIcon={<SyncIcon />}
                      onClick={() => runLoanDiskSync()}
                    >
                      Sync
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>

            <Box mt={3}>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "#003f5c",
                  color: "white",
                  padding: "8px",
                  textAlign: "center",
                  fontWeight: "bold",
                  borderRadius: "4px",
                }}
              >
                Background Checks
              </Typography>

              <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                {backgroundCheckData.map((item, index) => (
                  <Grid item xs={6} md={4} key={index}>
                    <Card
                      sx={{
                        borderRadius: "8px",
                        position: "relative",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                      onClick={item.handleClick}
                    >
                      <CardContent>
                        <Typography
                          color="black"
                          display="flex"
                          alignItems="center"
                          fontWeight="bold"
                        >
                          {item.icon}{" "}
                          <span style={{ marginLeft: "8px" }}>
                            {item.label}
                          </span>
                        </Typography>
                        <Typography variant="h6" fontWeight="bold">
                          {item.value}
                        </Typography>
                      </CardContent>
                      {/* RAG Status Circle */}
                      <Box
                        sx={{
                          width: "16px",
                          height: "16px",
                          borderRadius: "50%",
                          backgroundColor: RAG_COLORS[item.status],
                          position: "absolute",
                          bottom: "10px",
                          right: "10px",
                        }}
                      />
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </div>
        </Box>
      </Modal>

      <AffordabilityAnalysisModal
        open={openAffordabilityAnalysisModal}
        onClose={() => setOpenAffordabilityAnalysisModal(false)}
      />

      <CreditScoreCardModal
        open={openCreditScoreCardModal}
        onClose={() => setOpenCreditScoreCardModal(false)}
      />

      {/* <FinancialScreenModal
				open={openFinancialDataModal}
				onClose={() => setOpenFinancialDataModal(false)}
			/> */}
      <EnterpreneurDetailsModal
        open={openUserProfileModal}
        onClose={() => setOpenUserProfileModal(false)}
        selectedApplication={selectedApplication}
      />
      <LinkedDirectorsModal
        open={openLinkedCompaniesModal}
        onClose={() => setOpenLinkedCompaniesModal(false)}
        selectedApplication={selectedApplication}
      />
      <CompanyDetailsModal
        open={openBusinessProfileModal}
        onClose={() => setOpenBusinessProfileModal(false)}
        selectedApplication={selectedApplication}
      />
      <CompanyDirectorsModal
        open={openCompanyDirectorsModal}
        onClose={() => setOpenCompanyDirectorsModal(false)}
        selectedApplication={selectedApplication}
      />
      <DefaultsModal
        open={openDefaultsModal}
        onClose={() => setOpenDefaultsModal(false)}
        data={data}
        type="consumer"
      />
      <DefaultsModal
        open={openCommercialDefaultsModal}
        onClose={() => setOpenCommercialDefaultsModal(false)}
        data={data}
        type="commercial"
      />
      <FinancialDataModal
        open={openFinancialDataModal}
        onClose={() => setOpenFinancialDataModal(false)}
        uploadedStatements={uploadedBankStatementsData || []}
        transactionHistories={transactionHistoriesData || []}
        type="OCR"
        //isFetchingTransactionHistories={isLoadingHistories}
        isFetchingUploadedStatements={isLoadingStatements}
      />
    </>
  );
};

export default DealProfileModal;
