import React, { useEffect, useState } from "react";
import {
	Box,
	Typography,
	IconButton,
	Modal,
	Grid,
	useTheme,
	Tooltip,
	Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CopyIcon from "@mui/icons-material/FileCopy";
import CustomButton from "../Elements/CustomButton";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomTabs from "../Elements/CustomTabs";
import CustomTable from "../Tables/CustomTable";
import { useFetchClientDeals } from "../../helpers/hooks/useClientService";
import LoadingIconButton from "../Elements/LoadingIconButton";
import toast from "react-hot-toast";
import CreditReportDetails from "../Forms/CreditReportDetails";
import { ContentCopyOutlined } from "@mui/icons-material";

interface ProfileDetailsModalProps {
	open: boolean;
	onClose: () => void;
	selectedApplication: any;
}

const CompanyDetailsModal: React.FC<ProfileDetailsModalProps> = ({
	open,
	onClose,
	selectedApplication,
}) => {
	const theme = useTheme();
	const businessFields = [
		{
			label: "Business Name",
			value: selectedApplication?.businessProfile?.businessName,
		},
		{
			label: "Company Reg No",
			value: selectedApplication?.businessProfile?.companyRegNo,
		},
		{
			label: "Financial Year End",
			value: selectedApplication?.businessProfile?.financialYearEnd,
		},
		{
			label: "Commercial Status",
			value: selectedApplication?.businessProfile?.commercialStatus,
		},
		{
			label: "Physical Address",
			value: selectedApplication?.businessProfile?.physicalAddress,
		},
		{
			label: "Director Count",
			value: selectedApplication?.businessProfile?.directorCount,
		},
		{
			label: "Legal Entity Type",
			value: selectedApplication?.businessProfile?.legalEntityType,
		},
		{ label: "Tax No", value: selectedApplication?.businessProfile?.taxNo },
	];

	const handleCopyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text).then(() => {
			toast.success("Copied to clipboard!");
		});
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100vw",
					height: "100vh",
					backgroundColor:
						theme.palette.mode == "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					position: "relative",
					top: "0%",
					overflowY: "auto",
				}}
			>
				<Box
					mb={2}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h6">Company Details</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={onClose}
						sx={{ ml: "auto" }}
					>
						<CloseIcon />
					</IconButton>
				</Box>

				<Box p={2}>
					<form>
						<Grid container spacing={2}>
							{businessFields.map((field, index) => (
								<Grid item xs={12} md={4} key={index}>
									<Box display="flex" alignItems="center">
										<CustomTextInput label={field.label} value={field.value} />
										<Tooltip title="Copy to Clipboard">
											<IconButton
												onClick={() =>
													field.value && handleCopyToClipboard(field.value)
												}
												sx={{ ml: 1 }}
												disabled={!field.value}
											>
												<ContentCopyOutlined
													color={field.value ? "success" : "disabled"}
												/>
											</IconButton>
										</Tooltip>
									</Box>
								</Grid>
							))}
						</Grid>
					</form>
				</Box>
			</Box>
		</Modal>
	);
};

export default CompanyDetailsModal;
