import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Modal,
  Grid,
  useTheme,
  TableCell,
  Tooltip,
  Divider,
  Card,
  CardContent,
  Table,
  TableBody,
  TextField,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PeopleIcon from "@mui/icons-material/People";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { blue, green, grey, orange, red, amber } from "@mui/material/colors";

interface AffordabilityAnalysisModalProps {
  open: boolean;
  onClose: () => void;
}

const AffordabilityAnalysisModal: React.FC<AffordabilityAnalysisModalProps> = ({
  open,
  onClose,
}) => {
  const theme = useTheme();
  const [dscrTarget, setDscrTarget] = useState("1.5");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDscrTarget(event.target.value);
  };

  // State for loan details
  const [loanDetails, setLoanDetails] = useState({
    amountRequested: 50000,
    duration: 90,
    interestRate: 0.002, // 0.20% per day
    originationFeePercent: 0,
  });

  // Computed values
  const totalInterestCharge = Math.round(
    loanDetails.amountRequested *
      loanDetails.interestRate *
      loanDetails.duration
  );
  const bulletPayment = loanDetails.amountRequested + totalInterestCharge;
  const originationFee =
    (loanDetails.originationFeePercent / 100) * loanDetails.amountRequested;
  const monthlyInstallment = Math.round(
    bulletPayment / (loanDetails.duration / 30)
  );

  // DSCR values (Example values)
  const dscrValues = [
    {
      label: "DSCR - Operating Profit Based",
      value: 6.2,
      targetDescription: "Based on: DSCR - Operating Profit Based",
      targetValue: "23,272",
    },
    {
      label: "DSCR - Net Operating Profit Based",
      value: 1.4,
      targetDescription: "Based on: DSCR - Net Operating Profit Based",
      targetValue: "5,156",
    },
    {
      label: "DSCR - Net CF Profit Based",
      value: 0.0,
      targetDescription: "Based on: DSCR - Net CF Profit Based",
      targetValue: null,
    },
    {
      label: "DSCR - Avg. Bank Balance",
      value: 4.7,
      targetDescription: "Based on: DSCR - Avg. Bank Balance",
      targetValue: "17,514",
    },
    {
      label: "DSCR - Disp. Income",
      value: 3.3,
      targetDescription: "Based on: DSCR - Disp. Income",
      targetValue: "12,292",
    },
  ];

  // Function to determine DSCR color
  const getDscrColor = (value: number | null) => {
    if (value === null || value === 0) return "#E57373"; // Red
    if (value < 1.5) return "#FFB74D"; // Amber
    return "#81C784"; // Green
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundColor:
            theme.palette.mode == "dark"
              ? theme.palette.background.default
              : theme.palette.background.paper,
          padding: "24px",
          borderRadius: "12px",
          margin: "auto",
          position: "relative",
          top: "0%",
          overflowY: "auto",
        }}
      >
        <Box
          mb={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Typography variant="h6">Affordability Analysis</Typography> */}
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            sx={{ ml: "auto" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Paper sx={{ padding: 3, maxWidth: 800, margin: "auto", mt: 4 }}>
          <Typography variant="h6" textAlign="center" gutterBottom>
            Debt Affordability Analysis
          </Typography>

          <Box
            p={2}
            borderRadius={1}
            sx={{ mt: 3, backgroundColor: "#639E9D" }}
          >
            <Typography variant="h6" color="#FFFF">
              Loan Details
            </Typography>
          </Box>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            {/* Loan Details Fields */}
            {[
              {
                label: "Amount Requested",
                key: "amountRequested",
                symbol: "R",
              },
              { label: "Duration", key: "duration", symbol: "Days" },
              {
                label: "Interest Rate (per day)",
                key: "interestRate",
                symbol: "%",
              },
              {
                label: "Origination Fee (%)",
                key: "originationFeePercent",
                symbol: "%",
              },
            ].map(({ label, key, symbol }) => (
              <Grid item xs={6} key={key}>
                <TextField
                  fullWidth
                  label={label}
                  variant="outlined"
                  type="number"
                  value={loanDetails[key as keyof typeof loanDetails]}
                  onChange={(e) =>
                    setLoanDetails({
                      ...loanDetails,
                      [key]: parseFloat(e.target.value) || 0,
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <Typography sx={{ ml: 1 }}>{symbol}</Typography>
                    ),
                  }}
                />
              </Grid>
            ))}

            {/* Computed Fields */}
            {[
              { label: "Origination Fee", value: originationFee, symbol: "R" },
              {
                label: "Total Interest Charge",
                value: totalInterestCharge,
                symbol: "R",
              },
              { label: "Bullet Payment", value: bulletPayment, symbol: "R" },
              {
                label: "Monthly Installment",
                value: monthlyInstallment,
                symbol: "R",
              },
            ].map(({ label, value, symbol }) => (
              <Grid item xs={6} key={label}>
                <TextField
                  fullWidth
                  label={label}
                  variant="outlined"
                  value={value.toLocaleString()}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <Typography sx={{ ml: 1 }}>{symbol}</Typography>
                    ),
                  }}
                />
              </Grid>
            ))}
          </Grid>

          <Box
            p={2}
            borderRadius={1}
            sx={{ mt: 3, backgroundColor: "#639E9D" }}
          >
            <Typography variant="h6" color="#FFFF">
              Affordability - Based on Historical Performance
            </Typography>
          </Box>

          <Box
            p={2}
            borderRadius={1}
            sx={{ mt: 3, backgroundColor: "#f5f5f5" }}
          >
            <Typography variant="subtitle1">
              Monthly Installment Affordability
            </Typography>
          </Box>

          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>DSCR (12-months)</TableCell>
                  <TableCell align="center"></TableCell>
                  <TableCell align="left">DSCR Target of</TableCell>
                  <TableCell align="right">
                    <TextField
                      variant="outlined"
                      value={dscrTarget}
                      onChange={handleChange}
                      InputProps={{
                        style: {
                          textAlign: "right",
                          backgroundColor: "#f5f5f5", // Subtle background color
                          borderRadius: "4px",
                          padding: "4px 8px",
                        },
                      }}
                      sx={{
                        width: "80px", // Adjust width as needed
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none", // Remove default border
                          },
                          "&:hover fieldset": {
                            border: "1px solid #bdbdbd", // Subtle border on hover
                          },
                          "&.Mui-focused fieldset": {
                            border: "1px solid #3f51b5", // Focus border
                          },
                        },
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dscrValues.map((dscr) => (
                  <TableRow key={dscr.label}>
                    <TableCell>{dscr.label}</TableCell>
                    <TableCell
                      align="center"
                      sx={{ bgcolor: getDscrColor(dscr.value) }}
                    >
                      {dscr.value !== null ? dscr.value.toFixed(1) : "N.E."}
                    </TableCell>
                    <TableCell align="left">
                      {dscr.targetDescription !== null
                        ? `${dscr.targetDescription}`
                        : "N.E."}
                    </TableCell>
                    <TableCell align="right">
                      {dscr.targetValue !== null
                        ? `R${dscr.targetValue.toLocaleString()}`
                        : "N.E."}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Modal>
  );
};

export default AffordabilityAnalysisModal;
