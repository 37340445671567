import React, { useEffect, useState } from "react";
import {
	Box,
	Typography,
	IconButton,
	Modal,
	Grid,
	useTheme,
	Tooltip,
	Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CopyIcon from "@mui/icons-material/FileCopy";
import CustomButton from "../Elements/CustomButton";
import CustomTextInput from "../Elements/CustomTextInput";
import CustomTabs from "../Elements/CustomTabs";
import CustomTable from "../Tables/CustomTable";
import { useFetchClientDeals } from "../../helpers/hooks/useClientService";
import LoadingIconButton from "../Elements/LoadingIconButton";
import toast from "react-hot-toast";
import CreditReportDetails from "../Forms/CreditReportDetails";
import { ContentCopyOutlined } from "@mui/icons-material";
import { useQuery } from "react-query";
import { getCommercialCreditReport } from "../../../common/services/retool-service";

interface ProfileDetailsModalProps {
	open: boolean;
	onClose: () => void;
	selectedApplication: any;
}

const CompanyDirectorsModal: React.FC<ProfileDetailsModalProps> = ({
	open,
	onClose,
	selectedApplication,
}) => {
	const theme = useTheme();
	const {
		data: commercialData,
		isLoading: commercialLoading,
		isRefetching: commercialRefetching,
		refetch: refetchCommercialReport,
	} = useQuery(
		["commercialCreditReports", selectedApplication?.userId],
		() =>
			selectedApplication?.userId
				? getCommercialCreditReport(selectedApplication?.userId)
				: Promise.resolve(null),
		{ staleTime: Infinity, enabled: !!selectedApplication?.userId }
	);

	const directorData =
		commercialData?.data?.data?.commercialPrincipalInformation || [];

	const handleCopyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text).then(() => {
			toast.success("Copied to clipboard!");
		});
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100vw",
					height: "100vh",
					backgroundColor:
						theme.palette.mode == "dark"
							? theme.palette.background.default
							: theme.palette.background.paper,
					padding: "24px",
					borderRadius: "12px",
					margin: "auto",
					position: "relative",
					top: "0%",
					overflowY: "auto",
				}}
			>
				<Box
					mb={2}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h6">Director Details</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={onClose}
						sx={{ ml: "auto" }}
					>
						<CloseIcon />
					</IconButton>
				</Box>

				<Box p={2}>
					<form>
						<Grid container spacing={2}>
							<Box mt={4}>
								<Typography variant="h6">Director Details</Typography>
								<CustomTable
									isLoading={commercialLoading}
									columns={[
										{ title: "Full Name", key: "fullname" },
										{ title: "Phone Number", key: "cellularNo" },
										{ title: "ID Number", key: "idNo" },
										{ title: "Birth Date", key: "birthDate" },
										{ title: "Director Status", key: "directorStatusCode" },
										{ title: "Appointment Date", key: "appointmentDate" },
									]}
									data={directorData}
									copyToClipboard={true}
								/>
							</Box>
						</Grid>
					</form>
				</Box>
			</Box>
		</Modal>
	);
};

export default CompanyDirectorsModal;
