export function BusinessData() {
	const useOfFundsData = [
		"Business Inputs",
		"Consumables",
		"Packaging",
		"Repairs/Maintenance",
		"Small Equipment",
		"Stock for Resale",
		"Wages/Salaries",
	];

	const suppliers = [
		"Alzu",
		"Corteva",
		"Dicla",
		"Obara",
		"Plantforum",
		"Rijk Zwaan",
		"Robelo",
		"Sakata",
		"Seed2Harvest",
		"Talborne",
		"Winfert",
	];

	const valueAddServices = [
		"Abbatoir/Butchery",
		"Cold Storage",
		"Equipment Rental/Contract Farming",
		"Farm Stall/Rental Outlet",
		"Feed Silage/Production",
		"Food Processing/Manufacturing",
		"Fresh Produce Packhouse",
		"No value Adding Activities",
	];
	const sourceOfWater = [
		"No water for Irrigation",
		"Borehole(s)",
		"Windmill/Wellpoint",
		"Municipal Water",
		"River Water",
		"Irrigation Scheme/Dam",
	];
	const sourceOfElectricity = [
		"Municipal/Eskom Supply",
		"Generators",
		"Solar/BioGas",
		"No Electricity Supply",
	];
	const sourceOfRevenue = ["Sales of Agricultural Products"];

	const sectors = [
		"Agriculture",
		"Food",
		"Logistics",
		"Healthcare",
		"Construction",
		"Automotive",
	];

	const commodities = [
		"Beverages",
		"Cash Crops (Vegetables/Herbs)",
		"Food",
		"Food Services (e.g., Catering)",
		"Fruits",
		"Nuts",
		"Grains & Oil Seeds(Dryland)",
		"Grains & Oil Seeds(Irrigated)",
		"Cattle",
		"Sheep & Goats",
		"Piggery",
		"Poultry (meat)",
		"Poultry (eggs)",
		"Fish/Aquaculture",
		"Cannabis/Hemp",
		"None",
	];

	const banks = [
		{ img: "/static/banks/bank1.png", title: "Absa" },
		{ img: "/static/banks/bank2.png", title: "Capitek Bank" },
		{ img: "/static/banks/bank3.png", title: "FNB" },
		{ img: "/static/banks/bank4.png", title: "NedBank" },
		{ img: "/static/banks/bank5.png", title: "Standard Bank" },
		{ img: "/static/banks/bank6.png", title: "Tyme" },
	];

	const businesses = [
		{
			id: "1",
			name: "AgriTech Innovations",
			summary: {
				monthlyRevenue: 1500000,
				revenuePercentage: 5,
				totalExpenses: 800000,
				expensesPercentage: 10,
				debtServiceCoverageRatio: 1.75,
				transactionsForReview: 10,
				pendingActions: 4,
			},
			todos: [
				{
					title: "Assess Farmer Loan Applications",
					dueDate: "2024-07-19",
				},
				{
					title: "Approve Loan Disbursements",
					dueDate: "2024-07-24",
				},
				{
					title: "Monitor Loan Repayments",
					dueDate: "2024-07-27",
				},
			],
			transactions: [
				{
					id: "1",
					account: "FNB_GoldBusiness_234",
					date: "2024-07-16",
					amount: 1234.56,
					debitCredit: "D",
					reference: "INV123456",
					vendor: "FutureFarm Solutions",
					warnings: ["Verify Transaction"],
				},
				{
					id: "2",
					account: "Absa_Cheque Business_976954",
					date: "2024-07-16",
					amount: 7700.56,
					debitCredit: "D",
					reference: "INV123456",
					vendor: "International School",
					warnings: ["Unauthorized use of funds"],
				},
			],
			entrepreneur: {
				name: "John Doe",
				role: "Director",
			},
			company: "AgriTech Innovations",
			financialProduct: "STIF",
			amount: 1000000,
			term: new Date("2024-08-01"),
			riskStatus: "Low",
		},
		{
			id: "2",
			name: "FarmEquip Dynamics",
			summary: {
				monthlyRevenue: 1800000,
				revenuePercentage: 8,
				totalExpenses: 900000,
				expensesPercentage: 12,
				debtServiceCoverageRatio: 1.85,
				transactionsForReview: 12,
				pendingActions: 6,
			},
			todos: [
				{
					title: "Evaluate Farmer Loan Applications",
					dueDate: "2024-07-21",
				},
				{
					title: "Process Loan Approvals",
					dueDate: "2024-07-23",
				},
				{
					title: "Track Loan Repayments",
					dueDate: "2024-07-26",
				},
			],
			transactions: [
				{
					id: "1",
					account: "FNB_GoldBusiness_567",
					date: "2024-07-14",
					amount: 3456.78,
					debitCredit: "D",
					reference: "INV789012",
					vendor: "GreenField Suppliers",
					warnings: ["Unauthorized use of funds"],
				},
				{
					id: "2",
					account: "Absa_Cheque Business_976954",
					date: "2024-07-16",
					amount: 7700.56,
					debitCredit: "D",
					reference: "INV123456",
					vendor: "International School",
					warnings: ["Unauthorized use of funds"],
				},
			],
			entrepreneur: {
				name: "Jane Smith",
				role: "Director",
			},
			company: "FarmEquip Dynamics",
			financialProduct: "Asset Finance",
			amount: 5000000,
			term: new Date("2024-09-15"),
			riskStatus: "Medium",
		},
		{
			id: "3",
			name: "AgroMachinery Hub",
			summary: {
				monthlyRevenue: 2000000,
				revenuePercentage: 10,
				totalExpenses: 950000,
				expensesPercentage: 15,
				debtServiceCoverageRatio: 1.9,
				transactionsForReview: 15,
				pendingActions: 5,
			},
			todos: [
				{
					title: "Update Financial Reports",
					dueDate: "2024-07-20",
				},
				{
					title: "Review Employee Performance",
					dueDate: "2024-07-22",
				},
				{
					title: "Implement New Marketing Strategy",
					dueDate: "2024-07-25",
				},
			],
			transactions: [
				{
					id: "1",
					account: "FNB_GoldBusiness_890",
					date: "2024-07-12",
					amount: 5678.9,
					debitCredit: "D",
					reference: "INV345678",
					vendor: "EcoSeed SA",
					warnings: ["Verify Transaction"],
				},
				{
					id: "2",
					account: "Absa_Cheque Business_976954",
					date: "2024-07-16",
					amount: 7700.56,
					debitCredit: "D",
					reference: "INV123456",
					vendor: "International School",
					warnings: ["Unauthorized use of funds"],
				},
			],
			entrepreneur: {
				name: "Alice Johnson",
				role: "Director",
			},
			company: "AgroMachinery Hub",
			financialProduct: "Purchase Order Finance",
			amount: 7500000,
			term: new Date("2024-10-05"),
			riskStatus: "High",
		},
		{
			id: "4",
			name: "CropCare Solutions",
			summary: {
				monthlyRevenue: 2200000,
				revenuePercentage: 7,
				totalExpenses: 1100000,
				expensesPercentage: 13,
				debtServiceCoverageRatio: 2.1,
				transactionsForReview: 8,
				pendingActions: 3,
			},
			todos: [
				{
					title: "Secure New Funding",
					dueDate: "2024-07-28",
				},
				{
					title: "Hire New Staff",
					dueDate: "2024-07-29",
				},
				{
					title: "Expand Office Space",
					dueDate: "2024-07-30",
				},
			],
			transactions: [
				{
					id: "1",
					account: "FNB_GoldBusiness_6789",
					date: "2024-07-18",
					amount: 8901.23,
					debitCredit: "D",
					reference: "INV567890",
					vendor: "AgriNurture SA",
					warnings: ["Unauthorized use of funds"],
				},
				{
					id: "2",
					account: "Absa_Cheque Business_976954",
					date: "2024-07-16",
					amount: 7700.56,
					debitCredit: "D",
					reference: "INV123456",
					vendor: "International School",
					warnings: ["Unauthorized use of funds"],
				},
				{
					id: "3",
					account: "FNB_GoldBusiness_432761",
					date: "2024-07-17",
					amount: 9012.34,
					debitCredit: "Cr",
					reference: "INV678901",
					vendor: "Farmland Co-op",
					warnings: ["Verify Transaction"],
				},
			],
			entrepreneur: {
				name: "Bob Brown",
				role: "Director",
			},
			company: "CropCare Solutions",
			financialProduct: "Invoice Finance",
			amount: 6000000,
			term: new Date("2024-11-20"),
			riskStatus: "Low",
		},
		{
			id: "5",
			name: "AgriHarvest Enterprises",
			summary: {
				monthlyRevenue: 2400000,
				revenuePercentage: 6,
				totalExpenses: 1200000,
				expensesPercentage: 14,
				debtServiceCoverageRatio: 1.95,
				transactionsForReview: 9,
				pendingActions: 7,
			},
			todos: [
				{
					title: "Redesign Website",
					dueDate: "2024-08-01",
				},
				{
					title: "Update Compliance Policies",
					dueDate: "2024-08-03",
				},
				{
					title: "Increase Social Media Presence",
					dueDate: "2024-08-05",
				},
			],
			transactions: [
				{
					id: "1",
					account: "FNB_GoldBusiness_456",
					date: "2024-07-19",
					amount: 1234.56,
					debitCredit: "D",
					reference: "INV789012",
					vendor: "Farmer's Best",
					warnings: ["Above Threshold"],
				},
				{
					id: "2",
					account: "Absa_Cheque Business_976954",
					date: "2024-07-16",
					amount: 7700.56,
					debitCredit: "D",
					reference: "INV123456",
					vendor: "International School",
					warnings: ["Unauthorized use of funds"],
				},
				{
					id: "3",
					account: "FNB_GoldBusiness_456432",
					date: "2024-07-18",
					amount: 2345.67,
					debitCredit: "Cr",
					reference: "INV890123",
					vendor: "AgriProspect",
					warnings: ["Unauthorized use of funds"],
				},
			],
			entrepreneur: {
				name: "Emma White",
				role: "Director",
			},
			company: "AgriHarvest Enterprises",
			financialProduct: "Trade Finance",
			amount: 4000000,
			term: new Date("2024-12-10"),
			riskStatus: "Medium",
		},
	];

	const southAfricanLocations = [
		"Gauteng",
		"KZN",
		"Eastern Cape",
		"North West",
		"Western Cape",
		"Free State",
		"Northen Cape",
		"Mpumalanga",
	];
	const primaryCategories = [
		"Revenue",
		"Direct Expenses",
		"Overheads",
		"Unreconciled Expense",
		"Fixed Assets",
		"Current Assets",
		"Equity & Loans",
	];

	const secondaryCategories = [
		"Sales",
		"Other Revenue",
		"Unreconciled/Cash Sales",
		"Agricultural Inputs",
		"Packaging Consumables",
		"Labour (Seasonal/Casual)",
		"Purchases for Resale",
		"Fuel, Transport & Deliveries",
		"Accounting & Professional Services",
		"Bank Fees",
		"Income Tax Expense",
		"Insurance",
		"Interest Expenses",
		"Labour (Permanent, incl. staff welfare)",
		"Marketing",
		"Meals & Entertainment",
		"Transfer to Petty Cash",
		"Motor Vehicle Expenses",
		"Office Expenses",
		"Other General Expenses",
		"Personal Protection Equipment (PPE)",
		"Rent Expense",
		"Irrigation Equipment Expense",
		"Repair & Maintenance",
		"Security Expense",
		"Skills and Training Expense",
		"Small Tools & Equipments",
		"Subscriptions",
		"Telephone & Internet",
		"Travel",
		"Utilities & Rates (incl. water & electricity)",
		"Unreconciled Expense",
		"Livestock",
		"Motor Vehicle Asset",
		"Office & Computer Equipment Asset",
		"Plant, Property & Equipment",
		"Tractor & Implements",
		"Other Fixed asset",
		"Cash & Investments",
		"Equity",
		"Grant",
		"Loan",
		"Shareholder Loan",
		"Dividends",
	];

	return {
		primaryCategories,
		secondaryCategories,
		southAfricanLocations,
		businesses,
		banks,
		suppliers,
		useOfFundsData,
		commodities,
		sectors,
		valueAddServices,
		sourceOfElectricity,
		sourceOfRevenue,
		sourceOfWater,
	};
}
