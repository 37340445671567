import React, { useState, useEffect } from "react";
import {
	Modal,
	Box,
	Typography,
	IconButton,
	Tooltip,
	CircularProgress,
} from "@mui/material";
import {
	Close,
	Download,
	Edit,
	ManageAccounts,
	Visibility,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import CustomTable from "../Tables/CustomTable";
import CustomSelect from "../Elements/CustomSelect";
import CustomSearchBar from "../Elements/CustomSearchBar";
import { useFetchClientTransactions } from "../../../common/helpers/hooks/useClientService";
import { useFetchClientBankAccounts } from "../../../common/helpers/hooks/useBankAccountService";
import { useQueryClient } from "react-query";
import LoadingIconButton from "../Elements/LoadingIconButton";
import moment from "moment";
import CardWithIcon from "../Elements/CardWithIcon";
import ManagementAccountModal from "./ManagementAccountModal";
import UpdateTransactionHistoryModal from "./UpdateTransactionHistoryModal";
import toast from "react-hot-toast";
import {
	generateLabelledManagementAccount,
	generateManagementAccount,
} from "../../../common/services/retool-service";

interface FinancialDataModalProps {
	open: boolean;
	onClose: () => void;
	userId: string;
	isManagementAccountGenerated: boolean;
}

const LabelledFinancialDataModal: React.FC<FinancialDataModalProps> = ({
	open,
	onClose,
	userId,
	isManagementAccountGenerated,
}) => {
	const theme = useTheme();
	const queryClient = useQueryClient();
	const [searchQuery, setSearchQuery] = useState("");
	const [bankAccountId, setBankAccountId] = useState<number>(0);
	const [selectedRows, setSelectedRows] = useState<any[]>([]);
	const [openManagementAcountModal, setOpenManagementAccountModal] =
		useState(false);
	const [openUpdateTransactionHistory, setOpenUpdateTransactionHistory] =
		useState(false);
	const { data: bankAccounts = [], isLoading: isLoadingBankAccounts } =
		useFetchClientBankAccounts(userId);
	const [selectedRow, setSelectedRow] = useState<any>(null);

	const {
		data: transactions,
		isLoading,
		refetch,
		isRefetching,
	} = useFetchClientTransactions(userId, bankAccountId);

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value);
	};
	const [isGenerating, setIsGenerating] = useState(false);

	const filteredTransactions = transactions?.data?.data?.filter(
		(transaction: any) => {
			const description = transaction?.description?.toLowerCase() ?? "";
			const primaryCategory = transaction?.primaryCategory?.toLowerCase() ?? "";
			const secondaryCategory =
				transaction?.secondaryCategory?.toLowerCase() ?? "";

			return (
				description.includes(searchQuery.toLowerCase()) ||
				primaryCategory.includes(searchQuery.toLowerCase()) ||
				secondaryCategory.includes(searchQuery.toLowerCase())
			);
		}
	);

	const handleGenerateManagementAccount = async () => {
		setIsGenerating(true);

		try {
			const response = await generateLabelledManagementAccount(userId);

			if (response.data.success) {
				toast.success(response.data.message);
				queryClient.invalidateQueries("management-accounts");
				queryClient.invalidateQueries("clients");
			} else toast.error(response.data.message);
		} catch (error) {
			toast.error("Failed to delete document.");
		} finally {
			setIsGenerating(false);
		}
	};

	const arrayToCsvDownload = (array: any[], filename: string) => {
		const csvHeader = [
			"Date",
			"Description",
			"Amount",
			"Balance",
			"MA - Level 2",
			"MA - Level 1",
			"Red Flags Transaction?",
			"For Client Recon?",
		].join(",");

		const csvKeys = [
			"date",
			"description",
			"amount",
			"balance",
			"secondaryCategory",
			"primaryCategory",
		];

		const csvRows = array.map((row) =>
			csvKeys
				.map((key) => {
					if (key === "memo") {
						return `"${row[key] ?? row.descriptionLines?.[0] ?? ""}"`;
					}
					if (key === "clientRecon") {
						return `"${row[key] ? "Yes" : "No"}"`;
					}
					if (key === "flagType") {
						return `"${row[key] ?? ""}"`;
					}
					return `"${row[key] ?? ""}"`;
				})
				.join(",")
		);

		const csvString = `${csvHeader}\n${csvRows.join("\n")}`;
		const blob = new Blob([csvString], { type: "text/csv" });
		const link = document.createElement("a");
		const url = window.URL.createObjectURL(blob);

		link.href = url;
		link.download = `${filename}.csv`;

		document.body.appendChild(link);
		link.click();

		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	};

	const handleBankAccountChange = (
		event: React.ChangeEvent<{ value: unknown }>
	) => {
		setBankAccountId(event.target.value as number);
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box
				sx={{
					width: "100%",
					height: "100%",
					padding: "24px",
					backgroundColor: theme.palette.background.paper,
					overflow: "auto",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						mb: 3,
					}}
				>
					<Typography
						color={theme.palette.text.primary}
						sx={{ fontSize: "18px", fontWeight: "500" }}
					>
						Labelled Financial Data
					</Typography>
					<IconButton onClick={onClose}>
						<Close sx={{ fill: theme.palette.text.primary }} />
					</IconButton>
				</Box>

				<Box
					width="100%"
					my={2}
					gap={2}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box sx={{ flexGrow: 1 }}>
						<CustomSearchBar
							searchTerm={searchQuery}
							onSearchChange={handleSearch}
						/>
					</Box>
					<LoadingIconButton onClick={refetch} isLoading={isLoading} />
				</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					mb={2}
				>
					<Box width="300px">
						<CustomSelect
							otherLabel={false}
							name="bankName"
							label="Select Bank Account"
							value={bankAccountId || 0}
							onChange={(e: any) => handleBankAccountChange(e)}
							options={[
								{ label: "All", value: 0 },
								...(bankAccounts?.data?.data?.map((row: any) => ({
									label: `${row.bankName} ${row.accountNumber}`,
									value: row.id,
								})) || []),
							]}
							sx={{ mb: 2, width: "auto" }}
							error={
								isLoadingBankAccounts && bankAccounts?.data?.data?.length === 0
							}
							helperText={
								isLoadingBankAccounts
									? "Loading bank accounts..."
									: bankAccounts?.data?.data?.length === 0
										? "No bank accounts available"
										: ""
							}
						/>
					</Box>

					<Box
						display="flex"
						alignItems="center"
						gap={2}
						sx={{ marginLeft: "auto" }}
					>
						{isLoading ? (
							<CircularProgress size={20} color="success" />
						) : (
							<CardWithIcon
								icon={
									<ManageAccounts
										style={{
											color:
												theme.palette.mode === "light"
													? "rgba(73, 69, 79, 1)"
													: "white",
											marginRight: "5px",
											fontSize: "24px",
										}}
									/>
								}
								text="Generate M/A"
								onClick={() => handleGenerateManagementAccount()}
							/>
						)}

						{isManagementAccountGenerated === true && (
							<Box display="flex" alignItems="center" gap={1}>
								<CardWithIcon
									icon={
										<Visibility
											style={{
												color:
													theme.palette.mode === "light"
														? "rgba(73, 69, 79, 1)"
														: "white",
												marginRight: "5px",
												fontSize: "24px",
											}}
										/>
									}
									text="View M/A"
									onClick={() => setOpenManagementAccountModal(true)}
								/>
							</Box>
						)}

						<CardWithIcon
							icon={
								<Download
									style={{
										color:
											theme.palette.mode === "light"
												? "rgba(73, 69, 79, 1)"
												: "white",
										marginRight: "5px",
										fontSize: "24px",
									}}
								/>
							}
							text="Download"
							onClick={() =>
								arrayToCsvDownload(selectedRows, "extracted_transactions")
							}
							disabled={selectedRows.length < 1}
						/>
					</Box>
				</Box>

				<CustomTable
					isLoading={isLoading || isRefetching}
					columns={[
						{ title: "Date", key: "date", sortable: true },
						{ title: "Description", key: "description", sortable: true },
						{
							title: "Primary Category",
							key: "primaryCategory",
							sortable: true,
						},
						{
							title: "Secondary Category",
							key: "secondaryCategory",
							sortable: true,
						},
						{
							title: "Amount",
							key: "amount",
							sortable: true,
							withSignFormating: true,
						},
						{
							title: "Balance",
							key: "balance",
							sortable: true,
							withSignFormating: true,
						},
					]}
					data={filteredTransactions}
					selectable={true}
					onRowSelect={(selectedRows: any) => setSelectedRows(selectedRows)}
					selected={selectedRows}
					count={100}
					actionColumn={{ title: "Actions" }}
					icons={(statement) => [
						{
							icon: (
								<Tooltip title="Update" arrow>
									<Edit
										sx={{
											fill: theme.palette.mode === "light" ? "green" : "white",
											fontSize: "20px",
										}}
									/>
								</Tooltip>
							),
							onClick: () => {
								setSelectedRow(statement);
								setOpenUpdateTransactionHistory(true);
							},
						},
					]}
				/>

				<ManagementAccountModal
					open={openManagementAcountModal}
					onClose={() => setOpenManagementAccountModal(false)}
					userId={userId}
				/>
				<UpdateTransactionHistoryModal
					open={openUpdateTransactionHistory}
					onClose={() => setOpenUpdateTransactionHistory(false)}
					initialData={selectedRow}
					selectedRow={selectedRow}
					handleRefetch={refetch}
				/>
			</Box>
		</Modal>
	);
};

export default LabelledFinancialDataModal;
